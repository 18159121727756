import FormCheckBox from "../../../../components/forms/form-checkbox";
import OnLoadViewer from "../../../../components/on-load-viewer/on-load-viewer";
import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {ResourceContext} from "../resource-context/resource-context";
import ResourceRows from "./resource-row";
import {ResourceActionTypes} from "../../../../redux/types/resource-types";
import {Dispatch} from "redux";

const ResourceTable = () => {

    const resource = useContext(ResourceContext);
    const {resources, loading, selected_resources} = useSelector((state: RootState) => state.resources);
    const mediaQuery = useSelector((state: RootState) => state.mediaQueries);
    const dispatch = useDispatch<Dispatch<ResourceActionTypes>>();


    const resource_items = resources[resource.resource_id] ?? [];
    const resource_loading = loading[resource.resource_id] ?? true;
    const resource_selected = selected_resources[resource.resource_id] ?? [];

    const table_schema = mediaQuery.mdDown ? resource.small_table_data_schema : resource.table_data_schema;

    return (
        <table className="w-full text-left">
            {
                resource.has_table_checkbox && (
                    <colgroup>
                        <col className={'w-12'}/>
                    </colgroup>
                )
            }
            <thead className={`sticky top-64 md:top-56 z-30 ${resource_loading ? 'opacity-0' : 'opacity-100'}`}>
                <tr>
                    {
                        resource.has_table_checkbox && (
                            <th className="px-2 sm:px-3 cursor-pointer" onClick={() => {
                                if (resource_selected.length === resource_items.length)
                                    dispatch({type: "UNSELECT_ALL_RESOURCES ", payload: { resource_id: resource.resource_id }})
                                else
                                    dispatch({type: "SELECT_RESOURCES", payload: {
                                        resource_id: resource.resource_id,
                                        selected_resources: resource_items.map(o => o.id)
                                    }})
                            }}>
                                <FormCheckBox className={'justify-center w-full -translate-y-px'} onChange={() => {}} checked={resource_items.length > 0 && resource_selected.length === resource_items.length}/>
                            </th>
                        )
                    }

                    {
                        table_schema.map((th, index) => {
                            return (
                                <th key={`th_${index}`} className={`${th.mq_class} ${index === (table_schema.length-1) ? "text-right" : ""}`}>
                                    {th.column_header}
                                </th>
                            )
                        })
                    }
                    {/*<th className="lg:table-cell hidden"><div className={'flex items-center space-x-2'}><span>Date</span><ChevronDown set={"bold"} size={16} className={'opacity-30'}/></div></th>*/}
                </tr>
            </thead>
            <tbody className={`relative ${resource_loading ? 'opacity-0' : 'opacity-100'}`}>
                <OnLoadViewer component={"tr"} loading={resource_loading} className={"absolute"}>
                    <ResourceRows/>
                </OnLoadViewer>
            </tbody>
        </table>
    )

}

export default ResourceTable;