import Card from "../../../../components/cards/card";
import React, {useEffect, useState} from "react";
import {CatalogueImages, ProductItem} from "../../../../models/product-item";
import SlidingDialog from "../../../../components/dialogs/sliding-dialog";
import ResourceHeading from "../../components/resource-heading/resource-heading";
import FileSelector from "../../../../page-components/file-selector/file-selector";
import {FiX} from "react-icons/fi";

const ProductCatalogue = ({product, onChange}: {product?: ProductItem, onChange: (value: any) => void}) => {

    const [selector, setSelector] = useState("");
    const [image, setImage] = useState<CatalogueImages>();

    useEffect(() => {
        setImage(product?.catalogue_images);
    }, [product])

    const Image = (type: "default" | "hover", ) => {

        const imageExists = image?.imageKitLink(type) !== "";

        return (
            <div className={"group relative bg-gray-100 w-full aspect-h-10 aspect-w-9 rounded-lg overflow-hidden"}>
                {imageExists &&
                    <img alt={"Images for " + product?.title} src={image?.imageKitLink(type)} className={"object-cover w-full"}/>
                }
                {imageExists &&
                    <div className={"flex items-end space-x-2 transform -translate-y-3 absolute bottom-3 left-3 transition-all duration-200 opacity-0 group-hover:opacity-100"}>
                        <button
                            onClick={() => { setSelector(type) }}
                            className={"px-3.5 flex items-center h-8 rounded-full focus:outline-none ring-offset-transparent focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-raisinBlack text-white shadow-sm aspect-none bottom-3 left-3"}>
                            Change Image
                        </button>
                        <button
                            onClick={() => {
                                setImage((prevState) => {
                                    let t = prevState;
                                    if (t) {
                                        if (type === "default")
                                            t.defaultImage = "";
                                        else
                                            t.onHoverImage = '';

                                    }
                                    onChange({
                                        catalogue_images: t?.toJson()
                                    });
                                    return t;
                                })
                            }}
                            className={"w-8 justify-center flex items-center h-8 rounded-full focus:outline-none ring-offset-transparent focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-raisinBlack text-white shadow-sm aspect-none"}>
                            <FiX className={"text-white"}/>
                        </button>
                    </div>
                }
                {!imageExists &&
                    <div>
                        <button
                            onClick={() => { setSelector(type) }}
                            className={"absolute px-3.5 py-1.5 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-primary-default text-primary-lighter shadow-sm aspect-none bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2"}>
                            Add
                        </button>
                    </div>
                }
            </div>
        )
    }

    return (
        <Card className={"overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm"}>
                Catalogue Images
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
                <p>
                    Default
                </p>
                <p>
                    Hover
                </p>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
                {Image("default")}
                {Image("hover")}
            </div>
            <SlidingDialog open={selector === "default" || selector === "hover"} onClose={() => { setSelector("") }}>
                <div className={"p-6 bg-gray-50 sticky top-0"}>
                    <ResourceHeading returnTo={() => { setSelector("") }} title={"Select Images"}/>
                </div>
                <div className={"p-6"}>
                    <FileSelector onSelect={(v) => {
                        setSelector("");
                        setImage((prevState) => {
                            let t = prevState;
                            if (t) {
                                if (selector === "default")
                                    t.defaultImage = v;
                                else
                                    t.onHoverImage = v;

                            }
                            onChange({
                                catalogue_images: t?.toJson()
                            });
                            return t;
                        })
                    }} allowed={["images"]}/>
                </div>
            </SlidingDialog>

        </Card>
    )

}

export default ProductCatalogue;