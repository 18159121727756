import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import { createStore } from 'redux';
import allReducers from "./redux/index.reducers";
import * as serviceWorkerRegistration from './service-worker-registration';
import './index.css';
import './styles/table-styles.css';
import './styles/pill-styles.css';

const store = createStore(allReducers);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
    {
        path: "/*",
        element: <App />
    },
]);

root.render(
    <>
        <React.StrictMode>
            <Provider store={store}>
                <RouterProvider router={router}/>
            </Provider>
        </React.StrictMode>
    </>
);

serviceWorkerRegistration.register();
