import React from "react";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {Search} from 'react-iconly'
import Overview from "./overview/overview";
import Products from "./products/products";
import ProductDetails from "./products-details/product-details";
import OrderDetails from "./order-details/order-details";
import Customers from "./customers/customers";
import CustomerDetails from "./customer-details/customer-details";
import NewOrder from "./new-order/new-order-base";
import Logo from "../../components/logo/logo";
import {HiMenuAlt3} from "react-icons/hi";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import Icons from "../../components/icons/icons";
import {ResourceContext} from "./components/resource-context/resource-context";
import ResourceList from "./components/resource-list/resource-list";
import ThemeSwitcher from "../../components/theme-switcher/theme-switcher";
import Files from "./files/files";

const DashboardWrapper = () => {

    const location = useLocation();
    const {resource_schemas} = useSelector((state: RootState) => state.resource_schemas)

    const menuItems = [
        {
            icon: 'grid',
            label: "Overview",
            to: "/"
        },
        {
            icon: 'folder',
            label: "Files",
            to: "/files"
        },
        ...resource_schemas.filter(rs => rs.resource_menu_display).map(rs => {
            return {
                icon: rs.resource_menu_icon,
                label: rs.resource_title_plural,
                to: `/${rs.resource_slug}`
            }
        }),
        {
            icon: 'ticket',
            label: "Products",
            to: "/products"
        },
        {
            icon: 'two-users',
            label: "Customers",
            to: "/customers"
        },
        {
            icon: 'discount',
            label: "Discounts",
            to: "/discounts"
        },
        {
            icon: 'bar-chart',
            label: "Analytics",
            to: "/analytics"
        }
    ]

    return (
        <div className="flex text-sm">

            {/*Drawer*/}
            <div className="sticky left-0 top-0 h-screen pl-5 py-6 pr-0 flex-shrink-0 w-1/4 lg:w-1/5 xl:w-1/6  flex-col hidden md:flex">
                <div className={'bg-background-dark rounded-xl h-full overflow-y-auto border border-line-dark flex flex-col'}>
                    <div className={"h-16 px-4 flex w-full items-center justify-start"}>
                        <div className={'pl-2 flex items-center space-x-3'}>
                            <Logo className={'h-7 fill-primary-light'}/>
                            <div className={'font-bold text-lg tracking-wide text-primary-light select-none'}>
                                CMS
                            </div>
                        </div>
                    </div>
                    <div className={'w-full px-4'}>
                        <button className={'w-full bg-lowlight px-4 h-10 flex items-center transition duration-300 rounded-xl border border-line-dark hover:bg-highlight hover:text-primary-default focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-default focus-visible:outline-offset-2'}>
                            <Search className={'opacity-80 flex-shrink-0'} size={18} set={"curved"}/>
                            <span className={'ml-4'}>Search</span>
                        </button>
                    </div>
                    <div className="flex flex-col mt-6 space-y-1">
                        {
                            menuItems.map((item) => {

                                const active = (location.pathname.startsWith(item.to) && item.to !== "/") || (location.pathname === item.to);

                                return (
                                    <div key={item.to} className={'px-4 w-full'}>
                                        <Link key={item.to} to={item.to} className={`group relative px-4 h-10 flex w-full items-center justify-start font-medium hover:bg-higherlight focus-visible:bg-highlight focus-visible:border-line ${active ? "opacity-100 text-primary-default bg-highlight shadow-sm" : "opacity-90"} rounded-xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-default focus-visible:outline-offset-2`}>
                                            <Icons id={item.icon} size={18} className={`flex-shrink-0 opacity-80 transition duration-200 ${active ? "text-primary-default opacity-100" : ""}`} set={'bulk'}/>
                                            <span className={"ml-4"}>
                                                {item.label}
                                            </span>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={'mt-auto p-4 flex items-center'}>
                        <ThemeSwitcher/>
                    </div>
                </div>
            </div>

            <div className="flex-grow flex flex-col items-center">

                {/*Responsive Navbar*/}
                <div className="h-16 w-full z-40 bg-background-default pt-2 px-2 sticky top-0 flex items-center md:hidden">
                    <div className={'p-3 pr-2 bg-background-dark rounded-xl flex items-center w-full h-full border border-line-dark'}>
                        <div className={'flex items-center space-x-3'}>
                            <Logo className={'h-7 fill-primary-light'}/>
                            <div className={'font-bold text-lg tracking-wide text-primary-light select-none'}>
                                CMS
                            </div>
                        </div>
                        <button className={'ml-auto bg-highlight px-4 pr-8 h-10 flex items-center transition duration-300 rounded-lg border border-line-dark hover:text-primary-default focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-default focus-visible:outline-offset-2'}>
                            <Search className={'opacity-80 flex-shrink-0'} size={18} set={"curved"}/>
                            <span className={'ml-4'}>Search</span>
                        </button>
                        <button className={'ml-2 bg-highlight px-3 h-10 flex items-center transition duration-300 rounded-lg border border-line-dark hover:text-primary-default focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-default focus-visible:outline-offset-2'}>
                            <HiMenuAlt3 className={'opacity-80 flex-shrink-0'} size={18}/>
                        </button>
                    </div>
                </div>

                {/*Content*/}
                <div className="flex-grow flex w-full">
                    <div className="flex-grow">
                        <div className={"max-w-screen-3xl px-4 md:px-10 mx-auto"}>
                            <Routes>
                                <Route path={'/'} element={<Overview/>} />

                                {
                                    resource_schemas.filter(rs => rs.resource_menu_display).map(resource => {
                                        return <React.Fragment key={`${resource.resource_slug}-routes`}>
                                            <Route key={`${resource.resource_slug}-create`} path={`/${resource.resource_slug}/new`} element={<NewOrder/>} />
                                            <Route key={`${resource.resource_slug}-list`} path={`/${resource.resource_slug}`} element={<ResourceContext.Provider value={resource}><ResourceList/></ResourceContext.Provider>} />
                                            <Route key={`${resource.resource_slug}-view`} path={`/${resource.resource_slug}/:id`} element={<OrderDetails/>} />
                                        </React.Fragment>
                                    })
                                }

                                <Route path={'/files'} element={<Files/>} />

                                <Route path={'/customers'} element={<Customers/>} />
                                <Route path={'/customers/:id'} element={<CustomerDetails/>} />

                                <Route path={'/products'} element={<Products/>} />
                                <Route path={'/products/:id'} element={<ProductDetails/>} />
                                {/*<OpenRoute>*/}
                                {/*    <div>404</div>*/}
                                {/*</OpenRoute>*/}
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboardWrapper;