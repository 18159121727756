import React from "react";
import { Link } from "react-router-dom";

interface PrimaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: React.FunctionComponent<any>,
    loading?: boolean
    to?: string
}

const PrimaryButton = (props: PrimaryButtonProps) => {

    const inputProps = {...props};
    delete inputProps.icon
    delete inputProps.loading
    delete inputProps.to

    const className = `transition duration-300 group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-xl text-primary-lighter dark:text-primary-dark bg-primary-light hover:bg-primary-default ring-2 ring-offset-2 ring-transparent ring-offset-background-default focus:outline-none focus:ring-primary-default ${inputProps.className}`

    const base = () => {
        return <>
            <div className={`absolute left-50 inset-y-0 flex items-center transition duration-100 ${props.loading ? "opacity-100" : "opacity-0"}`}>
                <svg className="animate-spin h-5 w-5 mr-3 text-primary-lighter dark:text-primary-dark" fill={"none"} viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </div>
            <span className={`absolute left-0 inset-y-0 flex items-center pl-3`}>
                {props.icon &&
                    React.createElement(props.icon, {className: "h-5 w-5 text-primary-lighter dark:text-primary-dark group-hover:opacity-90", "aria-hidden": "true"})
                }
            </span>
            <span className={`transition duration-100 ${props.loading ? "opacity-0" : "opacity-100"}`}>
                {props.children}
            </span>
        </>
    }

    return (
        props.to ?
                ((props.to.includes("http") || props.to.includes("mailto")) ?
                        <a id={props.id}
                           href={props.to}
                           target={"_blank"}
                           rel={"noreferrer"}
                           style={{ ...props.style, pointerEvents: (props.disabled  || props.loading) ? "none" : "unset"}}
                           className={className}
                        >
                            {base()}
                        </a>
                        :
                        <Link
                            id={props.id}
                            to={props.to}
                            style={{ ...props.style, pointerEvents: (props.disabled  || props.loading) ? "none" : "unset" }}
                            className={className}
                        >
                            {base()}
                        </Link>
                )
                :
                <button
                    id={props.id}
                    type={props.type}
                    {...inputProps}
                    style={{ ...props.style}}
                    disabled={props.disabled  || props.loading}
                    onClick={props.onClick===null ? () => {} : props.onClick}
                    className={className}
                >
                    {base()}
                </button>
    )

}

export default PrimaryButton;