import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import ResourceHeading from "../components/resource-heading/resource-heading";
import OverviewBlocks from "./sections/overview-blocks";
import OrdersToFulFill from "./sections/overview-to-fulfill";
import {messaging} from "../../../utils/firebase.utils";
import {getToken, isSupported} from "firebase/messaging"

const Overview = () => {

    const {userData} = useSelector((state: RootState) => state.userData);
    const [support, setSupport] = useState(false);
    const [token, setToken] = useState("");

    useEffect(() => {
        isSupported().then((s) => {
            setSupport(s);
            if (Notification.permission === "granted") {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistration()
                        .then(async (reg) => {
                            getToken(messaging, {
                                vapidKey: "BOk0krGCwVM45LZ7t3vz75nylSbOHx0qwp6_ze5nJyIKz6GPEK2L4b_yKg6XC0TfTKUoscsWj8yM1EwPmcxsiiE",
                                serviceWorkerRegistration: reg
                            }).then(a => {
                                setToken(a);
                            }).catch(e => {
                                alert(e)
                            });
                        })
                }
            } else {
                alert("No Permission")
            }
        }).catch(e => {
            console.log(e)
        })
    }, []);

    const startMessaging = () => {

        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission !== 'granted') return;
            console.log('Notification permission granted.');
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration()
                    .then(async (reg) => {
                        getToken(messaging, {
                            vapidKey: "BOk0krGCwVM45LZ7t3vz75nylSbOHx0qwp6_ze5nJyIKz6GPEK2L4b_yKg6XC0TfTKUoscsWj8yM1EwPmcxsiiE",
                            serviceWorkerRegistration: reg
                        }).then(a => {
                            setToken(a);
                        }).catch(e => {
                            alert(e)
                        });
                    })
            }
        })
    }

    return (
        <>
            <div className="sm:py-7 py-4 flex flex-col w-full border-b border-line-dark bg-background-default sticky top-0">
                <ResourceHeading title={`Hey ${userData?.firstName}`} subtitle={"Here's what's happening with your store today."}/>
            </div>
            <div className="sm:py-7 py-4 space-y-6 md:max-w-xl">
                <OverviewBlocks/>
                <OrdersToFulFill/>
                {support ? <button onClick={() => {startMessaging()}}>Turn On Push Notifications</button> : "No SUpport"}
                {token ? token : "No Token Yet"}
            </div>
        </>
    )
}

export default Overview;