
// Action Types
export const UPDATE_MEDIA_QUERIES = 'UPDATE_MEDIA_QUERIES';

interface UpdateMediaQueries {
    type: typeof UPDATE_MEDIA_QUERIES,
    payload: {}
}

export type MediaQueryActionTypes = UpdateMediaQueries;

export interface MediaQueryState {
    loading: boolean
    mdDown: boolean
}