import {MediaQueryActionTypes, MediaQueryState, UPDATE_MEDIA_QUERIES} from "../types/media-query-types";

const initialState: MediaQueryState = {
    loading: true,
    mdDown: false
};

export function mediaQueryReducer (state = initialState, action: MediaQueryActionTypes): MediaQueryState {
    switch (action.type) {
        case UPDATE_MEDIA_QUERIES:

            const mdDown: MediaQueryList = window.matchMedia("(max-width: 767px)");

            return {
                ...state,
                loading: false,
                mdDown: mdDown.matches
            };
        default:
            return state;
    }
}

export default mediaQueryReducer;