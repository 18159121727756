import {Folder} from "react-iconly";
import React from "react";

const FileFolder = ({name}: {name: string}) => {

    return (
        <>
            <button
                // onDoubleClick={() => { setPath("/"+prefix.name) }}
                className={"cursor-default flex items-center px-4 py-3 rounded-xl border border-line text-sm hover:bg-lowlight dark:hover:bg-higherlight dark:focus:hover:bg-primary-light/20 focus:bg-primary-light/20 hover:text-primary-dark dark:hover:text-primary-lighter focus:outline-none focus:border-primary-light/50"}
            >
                <Folder set="bulk" size={20} className={"text-primary-light mr-3 flex-shrink-0"}/>
                <span className={"truncate overflow-ellipsis"}>
                    {name}
                </span>
            </button>
        </>
    )

}

export default FileFolder