import React, {useEffect, useRef, useState} from "react";
import ResourceHeading from "../components/resource-heading/resource-heading";
import FormCheckBox from "../../../components/forms/form-checkbox";
import {products} from "../../../utils/firebase.utils";
import ProductRow from "./components/product-row";
import {onSnapshot, orderBy, query} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {ProductItem} from "../../../models/product-item";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {Dispatch} from "redux";
import {ProductActionTypes, PUSH_PRODUCTS} from "../../../redux/types/product-types";

const Products = () => {

    const ref = useRef<HTMLDivElement | null>(null);
    const [selected, setSelected] = useState<string[]>([]);

    const productsState = useSelector((state: RootState) => state.products);
    const dispatch = useDispatch<Dispatch<ProductActionTypes>>();

    useEffect(() => {

        let ref = query(
            products,
            orderBy('title')
        );

        let observer = onSnapshot(ref, (snapshot) => {
            if (!snapshot.empty) {
                let t: ProductItem[] = [];
                snapshot.docs.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    t.push(new ProductItem(data));
                })
                dispatch({type: PUSH_PRODUCTS, payload: { products: t }});
            }
        })

        return () => observer();
    }, [dispatch])

    const selectProduct = (id: string) => {
        let t: string[] = [...selected];

        if (t.includes(id)) {
            t.splice(t.indexOf(id), 1);
        } else {
            t.push(id);
        }
        setSelected(t);
    }

    return (
        <>
            <div className="sm:pt-7 pt-4 flex flex-col w-full bg-white sticky top-16 z-10" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                <ResourceHeading title={`Products`}/>
                <div className="flex items-center space-x-3 sm:mt-7 mt-4">
                    <div className="border-b-2 border-indigo-500 text-primary-default pb-1.5 font-medium">All</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5">Active</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Disabled</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Drafts</div>
                </div>
            </div>
            <div className="space-y-6">
                <table className="w-full text-left">
                    <thead className={`h-12 bg-white px-3 py-3 text-gray-400 sticky relative z-10`} style={{ top: `${ref.current ? `${(ref.current?.clientHeight ?? 0) + 64}px` : ""}`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="px-3">
                                <FormCheckBox onChange={() => { if (selected.length === productsState.products.length) setSelected([]); else setSelected(productsState.products.map(o => o.id)); }} checked={productsState.products.length > 0 && selected.length === productsState.products.length}/>
                            </th>
                            <th className="font-normal sm:p-3 py-2 px-1">Product</th>
                            <th className="font-normal sm:p-3 py-2 px-1 sm:text-gray-400 text-white">Status</th>
                        </tr>
                    </thead>
                    <tbody className="text-raisinBlack relative">
                        <OnLoadViewer loading={productsState.loading} className={"absolute"}>
                            {
                                productsState.products.map((product) => {
                                    return <ProductRow key={product.id} onSelect={selectProduct} selected={selected} product={product}/>
                                })
                            }
                        </OnLoadViewer>
                    </tbody>
                </table>
            </div>
        </>
)
}

export default Products;