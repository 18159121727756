export class Variation {
    tag: string = "";
    title: string = "";
    price: number = 0;
    mrp: number = 0;

    constructor(data?: { [field: string]: any }) {
        this.tag = data?.tag ?? "";
        this.title = data?.title ?? "";
        this.price = data?.price ?? 0;
        this.mrp = data?.mrp ?? 0;
    }

    toJson(): Variation {
        return Object.assign({}, this);
    }
}

export class SubItems {
    id: string = "";
    urlString: string = "";
    title: string = "";
    ingredients: string = "";
    size: string = "";

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.title = data?.title ?? "";
        this.urlString = data?.urlString ?? "";
        this.ingredients = data?.ingredients ?? "";
        this.size = data?.size ?? "";
    }

    toJson(): SubItems {
        return Object.assign({}, this);
    }
}

export class Ingredients {
    title: string = "";
    description: string = "";

    constructor(data?: { [field: string]: any }) {
        this.title = data?.title ?? "";
        this.description = data?.description ?? "";
    }

    toJson(): Ingredients {
        return Object.assign({}, this);
    }
}

export class CatalogueImages {
    defaultImage: string = "";
    onHoverImage: string = "";

    constructor(data?: { [field: string]: any }) {
        this.defaultImage = data?.defaultImage ?? "";
        this.onHoverImage = data?.onHoverImage ?? "";
    }

    imageKitLink (type: "default" | "hover") {
        let imgUrl = type === "default" ? this.defaultImage : this.onHoverImage;

        return imgUrl ? `https://ik.imagekit.io/groomd${imgUrl}?tr=f-webp,w-380` : "";
    }

    toJson(): CatalogueImages {
        return Object.assign({}, this);
    }
}

export class ProductItem {
    id: string = "";
    urlString: string = "";
    averageReview: number = 5;
    totalReviews: number = 0;
    recommendedPercentage: number = 0;
    subTitle: string = "";
    variation: Variation[] = [];
    subItems: SubItems[] = [];
    size: string = "";
    title: string = "";
    product_images: string[] = [];
    catalogue_images: CatalogueImages = new CatalogueImages();
    thumbnail: string = "";
    out_of_stock: boolean = false;
    disabled: boolean = false;
    description: string = "";
    directionSteps: string[] = [];
    directionTips: string[] = [];
    benefits: string[] = [];
    ingredients: Ingredients[] = [];
    category: string[] = [];
    categoryTitle: string = "";
    tags: string[] = [];
    taxable: boolean = true;
    priority: number = 0;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.urlString = data?.urlString ?? "";
        this.averageReview = data?.averageReview ?? 5;
        this.totalReviews = data?.totalReviews ?? 0;
        this.recommendedPercentage = data?.recommendedPercentage ?? 0;
        this.subTitle = data?.subTitle ?? "";
        if (data?.subItems) {
            this.subItems = [];
            data.subItems.forEach((s: any) => {
                this.subItems.push(new SubItems(s));
            });
        } else {
            this.subItems = [];
        }
        if (data?.variation) {
            this.variation = [];
            data.variation.forEach((v: any) => {
                this.variation.push(new Variation(v));
            });
        } else {
            this.variation = [];
        }
        this.title = data?.title ?? "";
        this.size = data?.size ?? "";
        this.product_images = data?.product_images!=null ? data.product_images : [];
        this.catalogue_images = new CatalogueImages(data?.catalogue_images);
        this.thumbnail = data?.thumbnail ?? "";
        this.out_of_stock = data?.out_of_stock ?? false;
        this.disabled = data?.disabled ?? false;
        this.description = data?.description ?? "";
        this.directionSteps = data?.directionSteps!=null ? data.directionSteps : [];
        this.directionTips = data?.directionTips!=null ? data.directionTips : [];
        this.benefits = data?.benefits!=null ? data.benefits : [];
        if (data?.ingredients) {
            this.ingredients = [];
            data.ingredients.forEach((ingredient: any) => {
                this.ingredients.push(new Ingredients(ingredient));
            });
        } else {
            this.ingredients = [];
        }
        this.tags = data?.tags!=null ? data.tags : [];
        this.category = data?.category!=null ? data.category : [];
        this.categoryTitle = data?.categoryTitle ?? "";
        this.taxable = data?.taxable ?? true;
        this.priority = data?.priority ?? 0;
    }

    toJson() {
        let v = this.variation.map((x) => { return Object.assign({}, x) });
        let i = this.ingredients.map((x) => { return Object.assign({}, x) });
        let s = this.subItems.map((x) => { return Object.assign({}, x) });

        let data = Object.assign({}, this);
        data.variation = v;
        data.ingredients = i;
        data.subItems = s;
        return data;
    }

    get lowestVariation (): Variation {
        let lowest = this.variation[0];
        this.variation.forEach(v => {
            if (lowest.price > v.price)
                lowest = v;
        })
        return lowest;
    }

    get thumbnail_src (): string {
        let w = 80;
        let imgUrl = this.thumbnail;

        return `https://ik.imagekit.io/groomd${imgUrl}?tr=f-webp,w-${w}`;
    }

    get subTitleString (): string {
        let s = "";
        if (this.ingredients.length > 0) {
            this.ingredients.forEach((ing, index) => {
                s += ing.title;
                if (index === this.ingredients.length - 2) {
                    s+= " & ";
                } else if (index !== this.ingredients.length - 1) {
                    s+=", ";
                }
            })
        } else {
            s = this.subTitle;
        }
        return s;
    }
}

export class CartItem extends ProductItem {
    quantity: number = 0;
    variationId: string = "";

    constructor(data?: { [field: string]: any }){
        super(data);
        this.quantity = data?.quantity ?? 0;
        this.variationId = data?.variationId ?? "";
    }

    get variationTitle(): string {
        return `${this.title} ${this.variation.length > 1 ? `(${this.variation.find(v => v.tag === this.variationId)?.title})` : ""}`
    }

    get selectionVariation(): string {
        return `${this.variation.length > 1 ? `${this.variation.find(v => v.tag === this.variationId)?.title}` : ""}`
    }

    get price(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.price ?? 0)
    }

    get totalPrice(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.price ?? 0) * this.quantity
    }

    get totalDiscount(): number {
        if (this.originalPriceTotal === 0)
            return 0;
        if (this.originalPriceTotal > this.totalPrice)
            return (this.originalPriceTotal * this.quantity) - this.totalPrice
        else
            return 0
    }

    get discount(): number {
        let mrp = (this.variation.find((item) => item.tag === this.variationId)?.mrp ?? 0);
        if (mrp === 0)
            return 0;
        if (mrp > this.price)
            return mrp - this.price
        else
            return 0
    }

    get originalPrice(): number {
        return (this.variation.find((item) => item.tag === this.variationId)?.mrp ?? 0);

    }

    get originalPriceTotal(): number {
        return this.originalPrice * this.quantity;

    }

    get originalPriceWithoutTax(): number {
        return this.originalPrice / 1.18;

    }

    get originalPriceTotalWithoutTax(): number {
        return this.originalPriceTotal / 1.18;

    }
}