import DateRangePicker from "../../../../components/date-picker/date-range-picker";
import React, {useState} from "react";
import PrimaryButton from "../../../../components/buttons/button";
import moment from "moment";
import SecondaryButton from "../../../../components/buttons/seondary-button";

const DateRangePanel = ({value = [], onChange, onClose}: {value: Date[], onChange: (v: Date[]) => void, onClose: () => void}) => {

    const [date_range, setDateRange] = useState<Date[]>(value);

    const changeDate= () => {
        if (date_range.length === 2) {
            let new_range: Date[] = []

            let start = date_range[0]
            let end = date_range[1]

            new_range.push(moment(start).startOf('day').toDate(), moment(end).endOf('day').toDate())

            onChange(new_range);
            onClose();
        }
    }

    const clearDate = () => {
        onChange([]);
        onClose();
    }

    return (
        <>
            <DateRangePicker onChange={setDateRange} value={date_range}/>
            <div className={'px-4 h-[3rem] flex items-start justify-end space-x-3'}>
                <div>
                    <SecondaryButton
                        style={{ padding: "4px 12px", borderRadius: "10px" }}
                        onClick={clearDate}
                    >
                        Clear
                    </SecondaryButton>
                </div>
                <div>
                    <PrimaryButton
                        style={{ padding: "4px 12px", borderRadius: "10px" }}
                        onClick={changeDate}
                    >
                        Apply
                    </PrimaryButton>
                </div>
            </div>
            {/*<div className={'px-4 h-[4.5rem] flex items-center justify-between'}>*/}
            {/*    <div className={'text-color-light font-medium'}>*/}
            {/*        {date_range.length === 2 ? `${moment(date_range[0]).format("DD MMM YYYY")} to ${moment(date_range[1]).format("DD MMM YYYY")}` : ``}*/}
            {/*    </div>*/}
                {/*<div>*/}
                {/*    <PrimaryButton*/}
                {/*        onClick={changeDate}*/}
                {/*    >*/}
                {/*        Apply*/}
                {/*    </PrimaryButton>*/}
                {/*</div>*/}
            {/*</div>*/}
        </>
    )

}

export default DateRangePanel;