import React, {useEffect, useRef, useState} from "react";
import ResourceHeading from "../components/resource-heading/resource-heading";
import {products} from "../../../utils/firebase.utils";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {ProductItem} from "../../../models/product-item";
import {useParams} from "react-router-dom";
import ProductStatus from "./components/product-status";
import {FiCircle} from "react-icons/fi";
import PrimaryButton from "../../../components/buttons/button";
import ProductMedia from "./components/product-media";
import ProductCatalogue from "./components/product-catalogue";

const ProductDetails = () => {

    const ref = useRef<HTMLDivElement>(null);
    const { id }: {id?:string} = useParams();
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState<ProductItem>();
    const [newProduct, setNewProductData] = useState<Record<string, any>>({});

    useEffect(() => {
        getDoc(doc(products, id)).then((doc) => {
            if (doc.exists()) {
                let data = doc.data();
                data.id = doc.id;
                setProductData(new ProductItem(data));
            }
            setLoading(false);
        });
    }, [id]);

    const onProductChange = (newValue: any) => {
        setNewProductData({
            ...newProduct,
            ...newValue
        });
    }

    const checkChanges = (obj: any): boolean => {
        return Object.keys(obj).filter((key) => {
            if (typeof obj[key] === "object") {
                return checkChanges(obj[key]);
            } else {
                // @ts-ignore
                return obj[key] !== productData?.toJson()[key]
            }
        }).length > 0
    }

    let changed = checkChanges(newProduct);

    return (
        <>
            <div className={`px-6 flex item-center justify-end fixed h-12 bg-raisinBlack w-full h-16 left-0 top-0 z-40 transition duration-200 ${changed ? "opacity-100" : "pointer-events-none opacity-0"}`}>
                <div className={"flex items-center justify-end"}>
                    <div className="font-medium text-white flex-shrink-0 mr-4">
                        Unsaved Changes
                    </div>
                    <PrimaryButton className={"bg-primary-default hover:bg-primary-light"} style={{ width: "150px" }} onClick={() => {
                        updateDoc(doc(products, productData?.id), newProduct).then(() => {
                            setNewProductData({});
                        })
                    }}>
                        Save
                    </PrimaryButton>
                </div>
            </div>
            <OnLoadViewer loading={loading}>
                <div className="sm:pt-7 py-4 flex flex-col w-full bg-white sticky top-0 z-30" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                    <ResourceHeading
                        title={<div className={"flex items-center space-x-4"}>
                            <p className="font-bold text-3xl text-gray-900">
                                {productData?.title ?? ""}
                            </p>
                            <div className={"flex"}>
                                {
                                    productData?.disabled ?
                                        <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                            Disabled
                                        </div>
                                        :
                                        (productData?.out_of_stock ?
                                                <div className={"flex items-center px-3 py-1 bg-gray-50 border border-1 border-gray-100 text-gray-800 rounded-full font-medium"}>
                                                    <FiCircle className={"mr-2 text-gray-800"} size={8} strokeWidth={5}/> Out of Stock
                                                </div>
                                                :
                                                <div className={"px-3 py-1 bg-green-100 text-green-800 rounded-full font-medium"}>
                                                    Active
                                                </div>
                                        )
                                }
                            </div>
                        </div>}
                        returnTo={"/products"}/>

                </div>
                <div className={"grid grid-cols-7 gap-6 py-6"}>
                    <div className="space-y-6 col-span-4 w-full">
                        <ProductCatalogue product={productData} onChange={onProductChange}/>
                        <ProductMedia product={productData} onChange={onProductChange}/>
                        {/*<Card>*/}
                        {/*    {JSON.stringify(newProduct)}*/}
                        {/*</Card>*/}
                    </div>
                    <div className="space-y-6 col-span-3 w-full">
                        <ProductStatus product={productData} onChange={onProductChange}/>
                    </div>
                </div>
            </OnLoadViewer>
        </>
)
}

export default ProductDetails;