import {FirebaseOptions} from "firebase/app"

const DEV_DOMAIN = "cms.groomd.co.in";

export const getFirebaseConfig: () => FirebaseOptions = () => {
    const domain = process.env.NODE_ENV === "development" ? DEV_DOMAIN : window.location.host;

    switch (domain) {
        case "cms.groomd.co.in":
        case "admin.groomd.co.in":
            return {
                apiKey: "AIzaSyABQ3Z0s62a3Z0FHBZwolbQDSAHo5-ql4M",
                authDomain: "groomd-mens.firebaseapp.com",
                databaseURL: "https://groomd-mens.firebaseio.com",
                projectId: "groomd-mens",
                storageBucket: "groomd-mens.appspot.com",
                messagingSenderId: "222946718821",
                appId: "1:222946718821:web:e7b5326f77645ae7af028c",
                measurementId: "G-04EB94LK8R"
            } as FirebaseOptions
        case "cms.krishnarealinfra.com":
            return {
                apiKey: "AIzaSyB6Hc9AqOLf7CYjXEkIjmqlP5WhVh6EHRc",
                authDomain: "krishna-heights.firebaseapp.com",
                projectId: "krishna-heights",
                storageBucket: "krishna-heights.appspot.com",
                messagingSenderId: "77587814655",
                appId: "1:77587814655:web:6d557d558ef0190e2054ca",
                measurementId: "G-W4PHLN8EEX"
            } as FirebaseOptions
        default:
            return {} as FirebaseOptions
    }

}

export const imageKitLink: (url:string, options?:{tr:string}) => string = (url, options) => {
    let IMAGEKIT_BASE;
    const domain = process.env.NODE_ENV === "development" ? DEV_DOMAIN : window.location.host;

    switch (domain) {
        case "cms.groomd.co.in":
        case "admin.groomd.co.in":
            IMAGEKIT_BASE = "groomd"
            break;
        case "cms.krishnarealinfra.com":
            IMAGEKIT_BASE = "atticcreation/kri"
            break;
        default:
            IMAGEKIT_BASE = ""
            break;
    }
    return `https://ik.imagekit.io/${IMAGEKIT_BASE}${url.replaceAll(" ", "%20")}?tr=${options?.tr ?? "f-webp"}`;
}