import React from "react";
import { Link } from "react-router-dom";
import {FiArrowLeft} from "react-icons/fi";

const ResourceHeading = ({title, subtitle, returnTo}: {title: string | React.ReactNode, subtitle?: string, returnTo?: string | (() => void)}) => {

    return (
        <div className="w-full">
            {returnTo && typeof returnTo === "string" &&
                <Link tabIndex={0} to={returnTo}  className={"flex items-center mb-1 text-gray-700 opacity-50 hover:opacity-100 hover:text-primary-light font-medium cursor-pointer"}>
                    <FiArrowLeft className={"mr-2"}/> Back
                </Link>
            }
            {returnTo && typeof returnTo !== "string" &&
                <div tabIndex={0} onClick={() => { if (typeof returnTo !== "string") returnTo() }}  className={"flex items-center mb-1 text-gray-700 opacity-50 hover:opacity-100 hover:text-primary-light font-medium cursor-pointer"}>
                    <FiArrowLeft className={"mr-2"}/> Back
                </div>
            }

            {typeof title === "string" ?
                <div className="flex items-center font-bold text-4xl">
                    {title}
                </div>
                :
                title
            }
            {subtitle &&
                <div className="text-xl text-color-light mt-1">
                    {subtitle}
                </div>
            }
        </div>
    )

}

export default ResourceHeading