import React, {useRef} from "react";
import ResourceHeading from "../components/resource-heading/resource-heading";

const NewOrder = () => {

    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <>
            <div className="sm:pt-7 py-4 flex flex-col w-full bg-background-default sticky top-16 z-30" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                <ResourceHeading
                    title={<div>
                        <p className="font-bold text-3xl text-gray-900">
                            Create Order
                        </p>
                    </div>}
                    returnTo={() => {
                        // TODO Improve return logic
                        // if (history.length > 1)
                        //     navigate(-1);
                        // else
                        //     navigate("/orders")
                    }}/>

            </div>
            <div className={"grid grid-cols-1 sm:grid-cols-7 gap-6 py-6"}>
                <div className="space-y-6 col-span-1 sm:col-span-4 w-full">
                {/*    <NewOrderItems order={orderData} updateOrder={(order: Order) => {*/}
                {/*        let tempOrder = new Order(order.toJson());*/}
                {/*        tempOrder.subtotal = 0;*/}
                {/*        tempOrder.items.forEach((it) => {*/}
                {/*            tempOrder.subtotal += it.originalPriceTotal*/}
                {/*        })*/}
                {/*        setOrderData(tempOrder)*/}
                {/*    }}/>*/}
                {/*    <NewOrderPayments order={orderData}/>*/}
                </div>
                <div className="space-y-6 col-span-1 sm:col-span-3 w-full">
                    {/*<NewCustomerDetails order={orderData}/>*/}
                </div>
            </div>
        </>
    )
}

export default NewOrder;