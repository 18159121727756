import React, {useEffect, useRef, useState} from "react";
import ResourceHeading from "../components/resource-heading/resource-heading";
import FormCheckBox from "../../../components/forms/form-checkbox";
import {users} from "../../../utils/firebase.utils";
import {onSnapshot, orderBy, query} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {UserData} from "../../../models/user-data";
import CustomerRows from "./components/customer-row";

const Customers = () => {

    const ref = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState<UserData[]>([]);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {

        let ref = query(
            users,
            orderBy('delivered_orders', 'desc')
        );

        let observer = onSnapshot(ref, (snapshot) => {
            if (snapshot.empty) {
                setCustomerData([])
            } else {
                let t: UserData[] = [];
                snapshot.docs.forEach((doc) => {
                    let data = doc.data();
                    t.push(new UserData(data));
                })
                setCustomerData(t);
            }
            setLoading(false);
        })

        return () => observer();
    }, [])

    const selectRow = (id: string) => {
        let t: string[] = [...selected];

        if (t.includes(id)) {
            t.splice(t.indexOf(id), 1);
        } else {
            t.push(id);
        }
        setSelected(t);
    }

    return (
        <>
            <div className="sm:pt-7 pt-4 flex flex-col w-full bg-white sticky top-16 z-10" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                <ResourceHeading title={`Customers`}/>
                <div className="flex items-center space-x-3 sm:mt-7 mt-4">
                    <div className="border-b-2 border-indigo-500 text-primary-default pb-1.5 font-medium">All</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5">New</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Returning</div>
                </div>
            </div>
            <div className="space-y-6">
                <table className="w-full text-left">
                    <thead className={`h-12 bg-white px-3 py-3 text-gray-400 sticky relative z-10`} style={{ top: `${ref.current ? `${(ref.current?.clientHeight ?? 0) + 64}px` : ""}`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="px-3 md:table-cell hidden">
                                <FormCheckBox onChange={() => { if (selected.length === customerData.length) setSelected([]); else setSelected(customerData.map(o => o.uid)); }} checked={customerData.length > 0 && selected.length === customerData.length}/>
                            </th>
                            <th className="font-normal sm:p-3 py-2 px-1">Customer</th>
                            <th className="font-normal sm:p-3 py-2 px-1 md:table-cell hidden">City</th>
                            <th className="font-normal sm:p-3 py-2 px-1">Order Vol.</th>
                            <th className="font-normal sm:p-3 py-2 px-1">Membership</th>
                        </tr>
                    </thead>
                    <tbody className="text-raisinBlack relative">
                        <OnLoadViewer component={"tr"} loading={loading} className={"absolute"}>
                            {
                                customerData.map((customer) => {
                                    return <CustomerRows key={customer.uid} onSelect={selectRow} selected={selected} customer={customer}/>
                                })
                            }
                        </OnLoadViewer>
                    </tbody>
                </table>
            </div>
        </>
)
}

export default Customers;