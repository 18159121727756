import React, {useEffect, useState} from "react";
import Icons from "../icons/icons";

const ThemeSwitcher = () => {

    const [dark, setDark] = useState(false);

    const triggerTheme = () => {
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    useEffect(() => {
        setDark(document.documentElement.classList.contains('dark'))
    }, [])

    const changeTheme = () => {
        if (dark) {
            localStorage.theme = 'light'; triggerTheme();
            setDark(false)
        } else {
            localStorage.theme = 'dark'; triggerTheme();
            setDark(true)
        }
    }

    return (
        <button onClick={changeTheme} className={`group relative px-3 h-10 flex items-center justify-start hover:bg-higherlight focus-visible:bg-highlight focus-visible:border-line rounded-xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-default focus-visible:outline-offset-2`}>
            <Icons id={dark ? "dark-theme" : "light-theme"} size={18} className={`flex-shrink-0 transition duration-200 opacity-80`}/>
        </button>
    )

}

export default ThemeSwitcher