import {ResourceSchema} from "../../models/resource-schema";

// Action Types
export const PUSH_RESOURCE_SCHEMAS = 'PUSH_RESOURCE_SCHEMAS';

interface PushResourceSchemas {
    type: typeof PUSH_RESOURCE_SCHEMAS,
    payload: {
        resource_schemas: ResourceSchema[]
    }
}

export type ResourceSchemaActionTypes = PushResourceSchemas;

export interface ResourceSchemaState {
    loading: boolean
    resource_schemas: ResourceSchema[]
}