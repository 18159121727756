import React, {useEffect, useState} from "react";
import {data_index} from "../../../../utils/firebase.utils";
// @ts-ignore
import { Buy, PaperDownload } from 'react-iconly'
import {FiChevronRight} from "react-icons/fi";
import { Link } from "react-router-dom";
import Loading from "../../../../components/on-load-viewer/loading";
import {doc, getDoc} from "firebase/firestore";

const OrdersToFulFill = () => {

    const [loading, setLoading] = useState(true);
    const [unfulfilledOrders, setUnfulfilledOrders] = useState<number>(0);
    const [downloadLink, setDownloadLink] = useState("");

    useEffect(() => {
        getDoc(doc(data_index, "order_status")).then((doc) => {
            if (doc.exists()) {
                setUnfulfilledOrders(doc.data()?.unfulfilled ?? 0);
                setDownloadLink(doc.data()?.today_invoices ?? "");
                setLoading(false);
            }
        });
    }, []);

    return (
        <div  className={"cursor-pointer appearance-none transition duration-200 rounded-xl relative block overflow-hidden border border-line w-full"}>
            <div className={`${loading ? "opacity-100" : "opacity-0"} transition duration-300 top-0 absolute left-4 h-full flex items-center`}>
                <Loading size={3}/>
            </div>
            <Link to={"/orders"} className={`${loading ? "opacity-0" : "opacity-100"} transition duration-300 flex items-center  px-4 py-2 bg-highlight hover:bg-background-dark`}>
                <Buy set={"bulk"} size={16} className={"mr-4 text-primary-default"}/>
                <p className={"text-base"}>
                    <span className={"font-medium text-primary-default"}>
                        {unfulfilledOrders} order{unfulfilledOrders === 1 ? "" : "s"}
                    </span>
                    {" "}to fulfill
                </p>
                <div className={"ml-auto"}/>
                <FiChevronRight className={"opacity-70"}/>
            </Link>
            {downloadLink &&
                <a href={downloadLink} target={"_blank"} rel={"noreferrer"} className={`${loading ? "opacity-0" : "opacity-100"} transition duration-300 flex items-center  px-4 py-2 bg-highlight hover:bg-background-dark`}>
                    <PaperDownload set={"bulk"} size={16} className={"mr-4 text-primary-default"}/>
                    <p className={"text-base"}>
                    <span className={"font-medium text-primary-default"}>
                        Today's
                    </span>
                        {" "}Invoices
                    </p>
                    <div className={"ml-auto"}/>
                    <FiChevronRight className={"opacity-70"}/>
                </a>
            }
        </div>
    )

}

export default OrdersToFulFill;