import {PUSH_RESOURCE_SCHEMAS, ResourceSchemaActionTypes, ResourceSchemaState} from "../types/resource-schema-types";

const initialState: ResourceSchemaState = {
    loading: true,
    resource_schemas: []
};

export function resourceSchemaReducer (state = initialState, action: ResourceSchemaActionTypes): ResourceSchemaState {
    switch (action.type) {
        case PUSH_RESOURCE_SCHEMAS:
            return {
                ...state,
                loading: false,
                resource_schemas: action.payload.resource_schemas
            };
        default:
            return state;
    }
}

export default resourceSchemaReducer;