import {ProductItem} from "../../models/product-item";

// Action Types
export const PUSH_PRODUCTS = 'PUSH_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

interface PushProducts {
    type: typeof PUSH_PRODUCTS,
    payload: {
        products: ProductItem[]
    }
}

interface UpdateProduct {
    type: typeof UPDATE_PRODUCT,
    payload: {
        product: ProductItem
    }
}

export type ProductActionTypes = PushProducts | UpdateProduct;


export interface ProductsState {
    loading: boolean,
    products: ProductItem[]
}