import {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {HiSelector} from "react-icons/hi";
import {FiCheck} from "react-icons/fi";

const SingleSelect = ({selectedValue, setSelected, options}: {selectedValue:any, setSelected: (val: any) => void, options: any[]}) => {

    return (
        <div className="w-full">
            <Listbox value={selectedValue?.name} onChange={(value) => { setSelected({name: value}); }}>
                <div className="relative mt-1">
                    <Listbox.Button
                        className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-sm border cursor-default focus:outline-none focus:ring-primary-default focus:z-20 sm:text-sm focus:border-primary-default">
                        <span className="block truncate font-medium">{!selectedValue ? "Select an Option" : selectedValue.name}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <HiSelector
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        enter={"transition ease-in duration-100"}
                        enterTo="opacity-100"
                        enterFrom="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 z-40 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                            {options.map((option, index) => (
                                <Listbox.Option
                                    key={index}
                                    className={({active}) => `${active ? 'text-primary-default bg-primary-lighter' : 'text-gray-900'} cursor-pointer select-none relative py-2 pl-10 pr-4`}
                                    value={option.name}
                                >
                                    {
                                        ({selected, active}) => (
                                            <>
                                                <span className={`${selected ? 'font-medium' : ''} block truncate`}>
                                                    {option.name}
                                                </span>
                                                {selected ?
                                                    <span className={`${active ? 'text-primary-default' : 'text-primary-light'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                      <FiCheck className="w-5 h-5" aria-hidden="true"/>
                                                    </span>
                                                    :
                                                    <span className={`${active ? 'text-primary-default' : 'text-gray-300'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                      <FiCheck className="w-4 h-4" aria-hidden="true"/>
                                                    </span>
                                                }
                                            </>
                                        )
                                    }
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default SingleSelect;