import Card from "../../../../components/cards/card";
import React from "react";
import {Order} from "../../../../models/order";
import {Popover} from "@headlessui/react";
import {formatCurrency} from "../../../../utils/others";
import {Link} from "react-router-dom";
import moment from "moment";
import PrimaryButton from "../../../../components/buttons/button";
import {FiCircle} from "react-icons/fi";
import {AiFillCaretDown} from "react-icons/ai";
import {FaRupeeSign} from "react-icons/fa";

const CustomerRecentOrder = ({order}: {order?: Order}) => {

    return (
        <Card className={"overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm flex items-center"}>
                Recent Order
            </div>
            <div>
                {order &&
                <div>
                    <div className={"flex items-center"}>
                        <Link to={`/orders` + order?.id} className={"text-primary-default underline text-base mr-2"}>
                            #{order?.orderId}
                        </Link>
                        {
                            (() => {
                                switch (order?.status) {
                                    case "processing":
                                        return <div className={"flex items-center px-2 py-px bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                            <FiCircle className={"mr-2 text-yellow-800"} size={8} strokeWidth={5}/> Unfulfilled
                                        </div>
                                    case "cancelled":
                                        return <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                            Cancelled
                                        </div>
                                    case "manifested":
                                        return <div className={"flex items-center px-2 py-px bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                            <FiCircle className={"mr-2 text-yellow-800 bg-yellow-800 rounded-full"} size={8} strokeWidth={5}/> Manifested
                                        </div>
                                    case "in-transit":
                                        return <div className={"px-2 py-px bg-gray-200 text-gray-800 rounded-full font-medium"}>
                                            Shipped
                                        </div>
                                    case "delivered":
                                    case "returned-by-customer":
                                        return <div className={"px-2 py-px bg-green-100 text-green-800 rounded-full font-medium"}>
                                            Delivered
                                        </div>
                                    case "returned":
                                        return <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                            Returned
                                        </div>
                                    case "failed":
                                        return <Popover className="relative h-full w-full">
                                            <Popover.Button className={"w-full select-none text-left outline-none focus:outline-none ring-0"}>
                                                <div className="flex items-center">
                                                    <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                                        Failed
                                                    </div>
                                                    <AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                                </div>
                                            </Popover.Button>
                                            <Popover.Panel className="absolute z-50 w-screen max-w-xs mt-1 transform left-0">
                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                                                    <div className="flex items-center p-4">
                                                        <div>
                                                            {order.failed_reason.map((text) => {
                                                                return <p>{text}</p>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Popover>
                                    default:
                                        return <div/>
                                }
                            })()
                        }

                    </div>
                    <div>
                        <p className={"text-sm mr-2 text-gray-500"}>
                            {moment(order.created).format("DD MMM. YYYY")} at {moment(order.created).format("hh:mm A")}
                        </p>
                    </div>
                </div>
                }
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"divide-y w-full"}>
                Order Items ({order?.items.length})
                {
                    order?.items.map((item) => {
                        return <div className={"grid grid-cols-5 py-3"} key={item.id}>
                            <div className={"col-span-3 flex"}>
                                <div className={"w-10 h-10 rounded-md mr-4"} style={{ background: `#f6f4f5 url(${item.thumbnail}) center center / cover` }}/>
                                <div>
                                    <p className={"font-medium text-sm"}>
                                        {item.title}
                                    </p>
                                    <div className="text-gray-400 sm">
                                        {item.selectionVariation}
                                    </div>
                                </div>
                            </div>
                            <div className={"text-raisinBlack text-sm ml-auto px-6"}>
                                {formatCurrency(item.price)} × {item.quantity}
                            </div>
                            <div className={"text-sm ml-auto text-raisinBlack pl-6"}>
                                {formatCurrency(item.totalPrice)}
                            </div>
                        </div>
                    })
                }
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            {order?.items?.length && order?.items?.length > 0 &&
            <div className={"pt-0 py-0 space-y-2"}>
                <div className={"flex justify-between"}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Subtotal — { order.items.length } item(s)</p>
                    <p className={"font-medium font-sans text-sm"}>{formatCurrency(order.subtotal)}</p>
                </div>
                {order?.retailDiscount > 0 &&
                <div className={"flex justify-between"}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Retail Discount</p>
                    <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.retailDiscount)}</p>
                </div>
                }
                {order?.promotionalDiscount > 0 &&
                <div className={"flex justify-between"}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Coupon Discount ({order.promoCode})</p>
                    <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.promotionalDiscount)}</p>
                </div>
                }
                {order?.memberDiscount > 0 &&
                <div className={"flex justify-between"}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Membership Discount</p>
                    <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.memberDiscount)}</p>
                </div>
                }
                <div className={"flex justify-between py-3"} style={{ borderBottom: "1px solid rgba(100,50,50,0.1)" }}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Shipping</p>
                    <p className={"font-medium font-sans text-sm"}>{ order.shippingCost === 0 ? "Free" :  `+ ₹ 95`}</p>
                </div>
                <div className={"flex justify-between items-start pt-2"}>
                    <div>
                        <p className={"font-medium font-sans "}>Total</p>
                        <p className={"font-normal font-sans  opacity-90"}>
                            Including {formatCurrency(order.taxes)} in taxes
                        </p>
                    </div>
                    <p className={"flex items-center font-bold font-sans text-xl"}>
                        <FaRupeeSign size={16}/>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0}).format(order.total)}
                    </p>
                </div>
            </div>
            }
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"flex w-full justify-end space-x-4 items-center"}>
                <Link to={`/orders` + order?.id} className={"text-primary-default underline text-sm mr-2"}>
                    View All Orders
                </Link>
                <div>
                    <PrimaryButton>
                        Create New Order
                    </PrimaryButton>
                </div>
            </div>
        </Card>
    )

}

export default CustomerRecentOrder;