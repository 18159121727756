import React, {useEffect, useRef, useState} from "react";
import ResourceHeading from "../components/resource-heading/resource-heading";
import {orders, users} from "../../../utils/firebase.utils";
import {doc, getDoc, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {useParams} from "react-router-dom";
import {UserData} from "../../../models/user-data";
import CustomerSnippet from "./components/customer-snippet";
import CustomerInfo from "./components/customer-info";
import {Order} from "../../../models/order";
import CustomerRecentOrder from "./components/customer-recent-order";
import Address from "../../../models/address";

const CustomerDetails = () => {

    const ref = useRef<HTMLDivElement | null>(null);
    const { id }: {id?:string} = useParams();
    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState<UserData>();
    const [lastOrder, setLastOrder] = useState<Order>();

    useEffect(() => {
        getDoc(doc(users, id)).then(async (doc) => {
            if (doc.exists()) {
                let data = doc.data();
                data.id = doc.id;
                setCustomerData(new UserData(data));
            }

            await getDocs(query(orders, where("uid", "==", id), orderBy("created", "desc"), limit(1))).then((doc) => {
                if (!doc.empty) {
                    setLastOrder(new Order(doc.docs[0].data()));
                }
            });

            setLoading(false);
        });
    }, [id]);

    return (
        <>
            <OnLoadViewer loading={loading}>
                <div className="sm:pt-7 py-4 flex flex-col w-full bg-white sticky top-16 z-30" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                    <ResourceHeading
                        title={<div>
                            <p className="font-bold text-3xl text-gray-900">
                                {customerData?.name ?? ""}
                            </p>
                            <p className="text-sm text-gray-600">
                                {customerData?.recent_city}, {customerData?.recent_state}
                            </p>

                        </div>}
                        returnTo={"/customers"}/>

                </div>
                <div className={"grid grid-cols-1 sm:grid-cols-7 gap-6 py-6"}>
                    <div className="space-y-6 col-span-1 sm:col-span-4 w-full">
                        <CustomerSnippet customer={customerData ?? new UserData()}/>
                        <CustomerRecentOrder order={lastOrder ?? new Order()}/>
                    </div>
                    <div className="space-y-6 col-span-1 sm:col-span-3 w-full">
                        <CustomerInfo customerData={customerData ?? new UserData()} address={lastOrder?.shipping ?? new Address()}/>
                    </div>
                </div>
            </OnLoadViewer>
        </>
)
}

export default CustomerDetails;