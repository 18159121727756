import {Popover} from "@headlessui/react";
import {FiPlus} from "react-icons/fi";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import DateRangePanel from "./date-range-panel";
import {useDispatch} from "react-redux";
import {Dispatch} from "redux";
import {
    Filter,
    FilterValueType,
    getDisplayValue,
    getQueryString,
    getWhereClause,
    interpretValue
} from "../filter-utils/filter-utils";
import StringListPanel from "./string-list-panel";
import {ResourceContext} from "../resource-context/resource-context";
import {REMOVE_WHERE_CLAUSES, ResourceActionTypes, UPDATE_WHERE_CLAUSES} from "../../../../redux/types/resource-types";
import useMediaQuery from "../../../../components/media-query/use-media-query";

const ResourceFilter = ({filter}: {filter: Filter}) => {

    const smDown = useMediaQuery("(max-width: 639px)");

    const [params, setParams] = useSearchParams();
    const resource = useContext(ResourceContext);
    const dispatch = useDispatch<Dispatch<ResourceActionTypes>>();

    const [value, setValue] = useState<FilterValueType>();

    const onChange = (new_value: FilterValueType) => {
        setParams((prev_params) => {
            prev_params.set(filter.field_name, getQueryString(new_value, filter.filter_type))
            return prev_params;
        });
    }

    const removeFilter = () => {
        setParams((prev_params) => {
            prev_params.delete(filter.field_name);
            return prev_params
        });
    }

    // Sets [value]
    useEffect(() => {
        if (params.get(filter.field_name)) {
            setValue(interpretValue(params.get(filter.field_name) ?? "", filter.filter_type))
        } else {
            setValue(undefined)
        }
    }, [params, filter]);

    // Dispatches [clauses]
    useEffect(() => {
        if (value) {
            dispatch({ type: UPDATE_WHERE_CLAUSES, payload: { resource_id: resource.resource_id, clauses: getWhereClause(value, filter) } })
        } else {
            dispatch({ type: REMOVE_WHERE_CLAUSES, payload: { resource_id: resource.resource_id, clauses_field: filter.field_name } })
        }
        // eslint-disable-next-line
    }, [value, dispatch, filter]);

    const filter_button = "flex-shrink-0 flex items-center space-x-2.5 rounded-[10px] px-2 pr-2.5 py-1 hover:bg-highlight";
    const filter_button_inactive = "border border-dashed border-line";
    const filter_button_active = "border border-solid border-line";

    return (
        <Popover className={'flex flex-shrink-0'}>
            <Popover.Button className={`${filter_button} ${value ? filter_button_active : filter_button_inactive}`}>
                {
                    ({open}) => {
                        return <>
                            <div role={"button"} className={'rounded-[4px] p-px bg-line'}
                                 onClick={(event) => {
                                     if (value) {
                                         if (!open)
                                             event.stopPropagation();
                                         removeFilter()
                                     }
                                 }}
                            >
                                <FiPlus size={12} className={`transition duration-300 ${value ? 'rotate-45' : ''}`}/>
                            </div>
                            <span className={'flex-shrink-0'}>{filter.label}</span>
                            {
                                value && (
                                    <>
                                        <div className={'h-3.5 w-px bg-line'}/>
                                        <span className={'font-medium text-primary-light flex-shrink-0'}>{value ? getDisplayValue(value, filter) : ''}</span>
                                    </>
                                )
                            }
                        </>
                    }
                }
            </Popover.Button>
            {
                smDown && (
                    <Popover.Overlay className={'w-full h-full fixed bg-zinc-600 dark:bg-background-default opacity-50 left-0 top-0 z-[60]'}/>
                )
            }
            <Popover.Panel
                className={`fixed sm:absolute z-[65] bg-highlight border border-black/10 dark:border-white/10 rounded-2xl shadow-xl mt-11 overflow-hidden ${smDown ? "left-3 right-3" : ""}`}
                style={{
                    bottom: smDown ? "calc(env(safe-area-inset-bottom) + 12px)" : ""
                }}
            >
                {
                    ({close}) => {
                        return (
                            <div className={'relative h-auto sm:h-[28rem] max-h-none max-w-none flex flex-col'}>
                                <div className={'relative max-w-full overflow-hidden'} style={{ flex: "1 1 auto" }}>
                                    {
                                        (() => {
                                            switch (filter.filter_type) {
                                                case "date-range":
                                                    return <DateRangePanel value={(value ?? []) as Date[]} onChange={onChange} onClose={close}/>
                                                case "string-list":
                                                    return <StringListPanel options={filter.filter_options} value={(value ?? []) as string[]} onChange={onChange} onClose={close}/>
                                                default:
                                                    return <></>
                                            }
                                        })()
                                    }
                                </div>
                            </div>
                        )
                    }
                }
            </Popover.Panel>
        </Popover>
    )

}

export default ResourceFilter;