import React, {useContext} from "react";
import ResourceHeading from "../resource-heading/resource-heading";
import ResourceActions from "../resource-actions/resource-actions";
import ResourceFilterGroups from "../resource-filter-group/resource-filter-group";
import ResourceFilter from "../resource-filter/resource-filter";
import {ResourceContext} from "../resource-context/resource-context";
import ResourceFetch from "../resource-fetch/resource-fetch";
import ResourceTable from "../resource-table/resource-table";

const ResourceList = () => {

    const resource = useContext(ResourceContext);

    return (
        <>
            <ResourceFetch/>
            <div className={"flex flex-col w-full bg-background-default sticky top-16 md:top-0 z-40"}>
                <div className={"h-24"}>
                    <div className={'flex items-start mt-4 sm:mt-7'}>
                        <ResourceHeading title={resource.resource_title_plural}/>
                        <ResourceActions resource_actions={resource.resource_actions}/>
                    </div>
                </div>
                <div className={"flex items-center space-x-3 h-8 relative border-b border-line"}>
                    <ResourceFilterGroups/>
                </div>
                <div className={'h-16 overflow-x-auto'}>
                    <div className={'flex items-center justify-start h-full'}>
                        <div className={'flex items-center space-x-3 h-full'}>
                            {
                                resource.filters.map((filter, index) => {
                                    return <ResourceFilter key={`${resource.resource_slug}_filters_${index}`} filter={filter}/>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={'h-0 md:h-8'}/>
            </div>
            <ResourceTable/>
        </>
    )

}

export default ResourceList;