import { combineReducers } from 'redux';
import userDataReducer from "./reducers/user-data-reducer";
import siteReducer from "./reducers/site-reducer";
import productReducer from "./reducers/product-reducer";
import resourceReducer from "./reducers/resource-reducer";
import resourceSchemaReducer from "./reducers/resource-schema-reducer";
import mediaQueryReducer from "./reducers/media-query-reducer";

const allReducers = combineReducers({
    userData: userDataReducer,
    site: siteReducer,
    products: productReducer,
    mediaQueries: mediaQueryReducer,
    resources: resourceReducer,
    resource_schemas: resourceSchemaReducer
});

export type RootState = ReturnType<typeof allReducers>
export default allReducers;