import React, {useContext} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ResourceContext} from "../resource-context/resource-context";

const ResourceFilterGroups = () => {

    const {filter_groups} = useContext(ResourceContext);
    const [params] = useSearchParams();

    const noMatch = () => {
        const active_list = filter_groups.map(fg => fg.is_active(params));
        return active_list.filter(s => s).length === 0
    }

    return (
        <>
            {
                filter_groups.map((filter_group, index) => {

                    let active = filter_group.is_active(params) || (noMatch() ? filter_group.default : false)

                    return (
                        <Link key={`filter_group_${index}_${filter_group.label.toLowerCase().replaceAll(" ","_")}`}
                              to={filter_group.query_string}
                              className={`flex items-center h-full border-b-2 px-3 ${active ? "text-primary-light border-primary-light font-medium" : "border-transparent text-color-light"}`}
                        >
                            {filter_group.label}
                        </Link>
                    )
                })
            }
        </>
    )

}

export default ResourceFilterGroups;