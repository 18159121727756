import queryString from "query-string";
import {DATE_PRESETS, isDateRangeActive} from "../../../../components/date-picker/date-preset-picker";
import moment from "moment";
import {WhereClause} from "../../../../redux/types/resource-types";

export type StringListType = "string-list"
export type DateRangeType = "date-range"
export type FilterType = StringListType | DateRangeType
export type FilterValueType = string[] | Date[]

// Filter Value
export type FilterValue =
    {
        field_name: string
        field_type: StringListType
        field_value: string[]
    } |
    {
        field_name: string
        field_type: DateRangeType
        field_value: Date[]
    }

// Filter Groups
export class FilterGroup {
    label: string
    default: boolean
    filter_settings: FilterValue[]

    constructor(data?: { [field: string]: any }) {
        this.label = data?.label ?? "";
        this.default = data?.default ?? false
        if (data?.filter_settings) {
            this.filter_settings = data?.filter_settings?.map((fs: any) => fs as FilterValue) ?? [];
        } else{
            this.filter_settings = [];
        }
    }

    get query_string() {
        if (this.filter_settings.length > 0) {
            const filter_to_apply: Record<string, string> = {};

            this.filter_settings.forEach(filter_setting => {
                switch (filter_setting.field_type) {
                    case "string-list":
                        filter_to_apply[filter_setting.field_name] = filter_setting.field_value.join(",")
                        break;
                    case "date-range":
                        filter_to_apply[filter_setting.field_name] = filter_setting.field_value.map(d => d.getTime()).join(",")
                        break;
                }
            })
            return `?${queryString.stringify(filter_to_apply, {})}`;
        }
        else
            return ""
    }

    is_active (params: URLSearchParams ) {

        if (Array.from(params.entries()).length === 0) {
            return this.default
        } else {
            let active = true;
            if (this.filter_settings.length === 0)
                return false
            else {
                this.filter_settings.forEach(fs => {
                    if (!isFilterMatch(fs.field_value, interpretValue(params.get(fs.field_name) ?? "", fs.field_type)))
                        active = false;
                })
                return active;
            }
        }
    }
}

// Filters
export interface FilterOption {
    label: string
    value: string
}

export interface StringListFilter {
    label: string
    filter_type: StringListType
    field_name: string
    filter_options: FilterOption[]
}

export interface DateRangeFilter {
    label: string
    filter_type: DateRangeType
    field_name: string
}

export type Filter = DateRangeFilter | StringListFilter

// Filter Utils
export const getQueryString: (filter_value: FilterValueType, type: FilterType) => string = (filter_value: FilterValueType, type: FilterType) => {
    if (filter_value && filter_value.length > 0) {
        switch (type) {
            case "string-list":
                return filter_value.join(",");
            case "date-range":
                return filter_value.map(d => {
                    if (d instanceof Date) {
                        return d.getTime()
                    } else {
                        return "0";
                    }
                }).join(',');
        }
    } else {
        return "";
    }
}

export const getDisplayValue: (value: FilterValueType, filter: Filter) => string | React.ReactNode = (value: FilterValueType, filter: Filter) => {
    if (value) {
        switch (filter.filter_type) {
            case "date-range":
                const preset = DATE_PRESETS.find(p => isDateRangeActive(p.date_range, value as Date[]))
                if (preset)
                    return preset.label
                else
                    return <>{moment(value[0]).format('D MMM YYYY')} <span className={'opacity-60 text-color'}>to</span> {moment(value[1]).format('D MMM YYYY')}</>;
            case "string-list":
                return value.map(str => (filter.filter_options?.find(op => op.value === str)?.label ?? '')).join(", ");
            default:
                return "";
        }
    } else {
        return "";
    }
}

export const getWhereClause: (value: FilterValueType, filter: Filter) => WhereClause[] = (value: FilterValueType, filter: Filter) => {
    switch (filter.filter_type) {
        case "string-list":
            return [{field_path: filter.field_name, op: 'in', value: value}];
        case "date-range":
            return [{field_path: filter.field_name, op: '>=', value: value[0]}, {field_path: filter.field_name, op: '<=', value: value[1]}]
        default:
            return [];
    }
}

export function interpretValue(param_value: string, type: FilterType): FilterValueType
export function interpretValue (param_value: string, type: unknown) {

    switch (type) {
        case "date-range":
            let dates: Date[] = [];
            param_value.split(",").forEach((str: string) => dates.push(new Date(parseInt(str))));
            return dates;
        case "string-list":
            return param_value.split(",");
    }
}

export const isFilterMatch = (a: FilterValueType, b: FilterValueType) => {
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length)
            return false;
        return JSON.stringify(a.sort()) === JSON.stringify(b.sort())
    } else {
        return false;
    }
}