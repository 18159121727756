import React from "react";
import {PiPlusBold, PiUploadSimpleBold} from "react-icons/pi";
import {Bookmark, Buy, Category, Chart, Discount, Folder, Ticket, TwoUsers} from "react-iconly";
import {TbMoonFilled, TbSunFilled} from "react-icons/tb";

const Icons = ({id, size, set, className = ""}: {id: string, size: number, className: string, set?: 'light' | 'bold' | 'two-tone' | 'bulk' | 'broken' | 'curved'}) => {

    switch (id) {
        case "plus":
            return <PiPlusBold size={size} className={className}/>
        case "upload":
            return <PiUploadSimpleBold size={size} className={className}/>
        case "cart":
            return <Buy size={size} set={set} className={className}/>
        case "two-users":
            return <TwoUsers size={size} set={set} className={className}/>
        case "ticket":
            return <Ticket size={size} set={set} className={className}/>
        case "discount":
            return <Discount size={size} set={set} className={className}/>
        case "bar-chart":
            return <Chart size={size} set={set} className={className}/>
        case "grid":
            return <Category size={size} set={set} className={className}/>
        case "folder":
            return <Folder size={size} set={set} className={className}/>
        case "light-theme":
            return <TbSunFilled size={size} className={className}/>
        case "dark-theme":
            return <TbMoonFilled size={size} className={className}/>
        case "bookmark":
            return <Bookmark size={size} set={set} className={className}/>
        default:
            return <></>
    }

}

export default Icons;