import {WhereFilterOp} from "firebase/firestore";

export interface WhereClause { field_path: string, op: WhereFilterOp, value: unknown }

// Action Types
export const PUSH_RESOURCES = 'PUSH_RESOURCES';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const UPDATE_WHERE_CLAUSES = 'UPDATE_WHERE_CLAUSES';
export const REMOVE_WHERE_CLAUSES = 'REMOVE_WHERE_CLAUSES';
export const SELECT_RESOURCES = 'SELECT_RESOURCES';
export const UNSELECT_ALL_RESOURCES = 'UNSELECT_ALL_RESOURCES ';

interface PushResources {
    type: typeof PUSH_RESOURCES,
    payload: {
        resource_id: string
        resources: { [field: string]: any }[]
        action: 'replace' | 'sync'
    }
}

interface UpdateResource {
    type: typeof UPDATE_RESOURCE,
    payload: {
        resource_id: string
        resource: { [field: string]: any }
    }
}

interface UpdateWhereClauses {
    type: typeof UPDATE_WHERE_CLAUSES,
    payload: {
        resource_id: string
        clauses: WhereClause[]
    }
}

interface RemoveWhereClauses {
    type: typeof REMOVE_WHERE_CLAUSES,
    payload: {
        resource_id: string
        clauses_field: string
    }
}

interface SelectResources {
    type: typeof SELECT_RESOURCES,
    payload: {
        resource_id: string
        selected_resources: string[]
    }
}

interface UnselectAllResources {
    type: typeof UNSELECT_ALL_RESOURCES,
    payload: {
        resource_id: string
    }
}

export type ResourceActionTypes = PushResources | UpdateResource | UpdateWhereClauses | RemoveWhereClauses | SelectResources | UnselectAllResources;

export interface ResourcesState {
    loading: { [field: string]: boolean }
    clause_changed: { [field: string]: boolean }
    resources: { [field: string]: any[] }
    clauses: { [field: string]: WhereClause[] }
    selected_resources: { [field: string]: string[] }
}