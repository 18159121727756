import {CartItem} from "./product-item";
import Address from "./address";
import CreditCard from "./credit-card";

export class Order{
    id: string = "";
    card: CreditCard;
    orderId: string = "";
    failed_reason: string[] = [];
    return_reason: {
        text: string,
        waybill: string
        status: string
    };
    paymentMethod: 'wallet' | 'cod' | 'card' | 'unselected' | 'prepaid'= 'unselected';
    paymentDetails: string = "";
    items: CartItem[] = [];
    shipping: Address = new Address();
    promoCode: string = "";
    subtotal: number = 0;
    discount: number = 0;
    promotionalDiscount: number = 0;
    retailDiscount: number = 0;
    taxes: number = 0;
    memberDiscount: number = 0;
    shippingCost: number = 0;
    total: number = 0;
    status: string = "";
    waybill: string = "";
    uid: string = "";
    created: Date = new Date();

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.orderId = data?.orderId ?? "";
        this.card = data?.card ? new CreditCard(data?.card) : new CreditCard();
        this.failed_reason = data?.failed_reason ?? [];
        this.paymentMethod = data?.paymentMethod ?? "unselected";
        this.return_reason = data?.return_reason ? { text: data.return_reason.text, waybill: data.return_reason.waybill, status: data.return_reason.status } : { text: "", waybill: "", status: "" };
        this.paymentDetails = data?.paymentDetails ?? "";
        if (data?.items!=null) {
            this.items = [];
            data.items.forEach((c: any) => {
                this.items.push(new CartItem(c));
            });
        } else {
            this.items=[];
        }
        this.shipping = data?.shipping ? new Address(data.shipping) : new Address();
        this.promoCode = data?.promoCode ?? "";
        this.subtotal = data?.subtotal ?? 0;
        this.discount = data?.discount ?? 0;
        this.retailDiscount = data?.retailDiscount ?? 0;
        this.promotionalDiscount = data?.promotionalDiscount ?? 0;
        this.taxes = data?.taxes ?? 0;
        this.memberDiscount = data?.memberDiscount ?? 0;
        this.shippingCost = data?.shippingCost ?? 0;
        this.total = data?.total ?? 0;
        this.status = data?.status ?? "";
        this.waybill = data?.waybill ?? "";
        this.uid = data?.uid ?? "";
        this.created = data?.created ? (typeof data?.createAt === "string" ? new Date(data.created) : (data.created instanceof Date ? data.created : new Date(data.created.toDate().toString()))) : new Date();
    }

    get summary() {
        return this.items.map((x) => {
            return Object.assign({}, x)
        });
    }

    get status_color() {
        switch (this.status)  {
            case "processing":
            case "manifested":
                return "yellow"
            case "in-transit":
                return "bare"
            case "delivered":
            case "returned-by-customer":
                return "green"
            case "cancelled":
            case "returned":
            case "failed":
                return "red"
            default:
                return "bare"
        }
    }

    get status_text() {
        switch (this.status)  {
            case "processing":
                return "Unfulfilled"
            case "cancelled":
                return "Cancelled"
            case "manifested":
                return "Manifested"
            case "in-transit":
                return "Shipped"
            case "delivered":
                return "Delivered"
            case "returned-by-customer":
            case "returned":
                return "Returned"
            case "failed":
                return "Failed"
            default:
                return "Unknown"
        }
    }

    toJson(): Order {
        let s = this.items.map((x) => {
            return Object.assign({}, x)
        });
        let data = Object.assign({}, this);
        data.items = s;

        return data;
    }

}