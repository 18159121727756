import React from "react";

interface FormCheckBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | React.ReactNode
}

const FormCheckBox = (props: FormCheckBoxProps) => {

    return (
        <div className={`flex items-start ${props.className}`}>
            <input
                {...props}
                type="checkbox"
                className={"h-4 w-4 bg-higherlight transform translate-y-0.5 text-primary-default focus:ring-primary-default focus:ring-offset-background-default border-line rounded-md cursor-pointer"}
            />
            {props.label && (
                <label htmlFor={props.id} className="ml-3 block text-sm">
                    {props.label}
                </label>
            )}
        </div>
    )

}

export default FormCheckBox;