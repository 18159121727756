import {Filter, FilterGroup} from "../main-pages/dashboard/components/filter-utils/filter-utils";

export class ResourceAction {
    type: "primary-button"
    label?: string
    icon?: string
    to?: string

    constructor(data?: { [field: string]: any }) {
        this.type = data?.type ?? "primary-button";
        this.label = data?.label ?? "";
        this.icon = data?.icon ?? "";
        this.to = data?.to ?? "";
    }
}

interface TableDataSchemeFieldFormatting {
    thumbnail: string
    prefix: string
    suffix: string
    date_format: string
    currency: string
    locale: string
    pill_configuration: {
        label: string
        value: string
        color: 'green' | 'bare' | 'red' | 'yellow'
        style: 'withdot' | 'filled' | 'outlined'
    }[]
}

export class TableDataScheme {
    column_header: string
    column_visibility: "" | "lg" | "md" | "sm"
    type: "main" | "basic"
    field_name: string
    field_type: "string" | "date" | "number" | "currency" | "pill"
    field_formatting: TableDataSchemeFieldFormatting
    sub_field_name: string
    sub_field_type: "string" | "date" | "number" | "currency" | "pill"
    sub_field_formatting: TableDataSchemeFieldFormatting
    pre_field_name: string
    pre_field_type: "string" | "date" | "number" | "currency" | "pill"
    pre_field_formatting: TableDataSchemeFieldFormatting

    constructor(data?: { [field: string]: any }) {
        this.column_header = data?.column_header ?? "";
        this.column_visibility = data?.column_visibility ?? "";
        this.type = data?.type ?? "basic";
        this.field_name = data?.field_name ?? "";
        this.field_type = data?.field_type ?? "string";
        this.field_formatting = data?.field_formatting as TableDataSchemeFieldFormatting;
        this.sub_field_name = data?.sub_field_name ?? "";
        this.sub_field_type = data?.sub_field_type ?? "string";
        this.sub_field_formatting = data?.sub_field_formatting as TableDataSchemeFieldFormatting;
        this.pre_field_name = data?.pre_field_name ?? "";
        this.pre_field_type = data?.pre_field_type ?? "string";
        this.pre_field_formatting = data?.pre_field_formatting as TableDataSchemeFieldFormatting;
    }

    get mq_class () {
        switch (this.column_visibility) {
            case "":
                return "";
            case "lg":
                return "lg:table-cell hidden"
            case "md":
                return "md:table-cell hidden"
            case "sm":
                return "sm:table-cell hidden"
            default:
                return ""
        }
    }

}

export class Sortable {
    sort_field: string
    sort_label: string
    lower_label: string
    higher_label: string

    constructor(data?: { [field: string]: any }) {
        this.sort_field = data?.sort_field ?? "";
        this.sort_label = data?.sort_label ?? "";
        this.lower_label = data?.lower_label ?? "";
        this.higher_label = data?.higher_label ?? "";
    }
}

export class ResourceSchema {

    resource_id: string
    resource_slug: string
    resource_title: string
    resource_title_plural: string
    resource_menu_display: boolean
    resource_menu_icon: string
    resource_actions: ResourceAction[]
    has_filter: boolean = false
    has_filter_group: boolean = false
    filter_groups: FilterGroup[] = []
    filters: Filter[] = []
    has_sort: boolean = false
    default_sort_field: string = ""
    default_sort_direction: "desc" | "asc" = "desc"
    sortables: Sortable[] = []
    has_table_checkbox: boolean = false
    table_data_schema: TableDataScheme[]
    small_table_data_schema: TableDataScheme[]

    constructor(data?: { [field: string]: any }) {
        this.resource_id = data?.resource_id ?? "";
        this.resource_slug = data?.resource_slug ?? "";
        this.resource_title = data?.resource_title ?? "";
        this.resource_title_plural = data?.resource_title_plural ?? "";
        this.resource_menu_display = data?.resource_menu_display ?? false;
        this.resource_menu_icon = data?.resource_menu_icon ?? "";
        if (data?.resource_actions) {
            this.resource_actions = data?.resource_actions?.map((ra: any) => new ResourceAction(ra)) ?? [];
        } else{
            this.resource_actions = [];
        }
        this.has_filter = data?.has_filter ?? false;
        this.has_filter_group = data?.has_filter_group ?? false;
        if (data?.filter_groups) {
            this.filter_groups = data?.filter_groups?.map((fg: any) => new FilterGroup(fg)) ?? [];
        } else{
            this.filter_groups = [];
        }
        if (data?.filters) {
            this.filters = data?.filters?.map((f: any) => f as Filter) ?? [];
        } else{
            this.filters = [];
        }
        this.has_sort = data?.has_sort ?? false
        this.default_sort_field = data?.default_sort_field ?? ""
        this.default_sort_direction = data?.default_sort_direction ?? "desc"
        if (data?.sortables) {
            this.sortables = data?.sortables?.map((s: any) => new Sortable(s)) ?? [];
        } else{
            this.sortables = [];
        }
        this.has_table_checkbox = data?.has_table_checkbox ?? false
        if (data?.table_data_schema) {
            this.table_data_schema = data?.table_data_schema?.map((d: any) => new TableDataScheme(d)) ?? [];
        } else{
            this.table_data_schema = [];
        }
        if (data?.small_table_data_schema) {
            this.small_table_data_schema = data?.small_table_data_schema?.map((d: any) => new TableDataScheme(d)) ?? [];
        } else{
            this.small_table_data_schema = [];
        }
    }

}