import React, {useContext} from "react";
import moment from "moment";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import {Link} from "react-router-dom";
import Pill from "../../../../components/pill/pill";
import {ResourceContext} from "../resource-context/resource-context";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {Dispatch} from "redux";
import {ResourceActionTypes} from "../../../../redux/types/resource-types";

const ResourceRows = () => {

    const dispatch = useDispatch<Dispatch<ResourceActionTypes>>();
    const resource = useContext(ResourceContext);
    const {resources: { [resource.resource_id]: resource_items }, selected_resources:  { [resource.resource_id]: resource_selected }} = useSelector((state: RootState) => state.resources);
    const mediaQuery = useSelector((state: RootState) => state.mediaQueries);

    const table_schema = mediaQuery.mdDown ? resource.small_table_data_schema : resource.table_data_schema;

    return (
        <>
            {
                (resource_items ?? []).map((item) => {

                    return (
                        <tr key={item.id} className={`border-b border-line-dark cursor-pointer group ${!mediaQuery.loading ? "opacity-100" : "opacity-0"} transition-opacity duration-300`}>
                            {
                                resource.has_table_checkbox && (
                                    <td className={'align-middle px-2 sm:px-3'}
                                        onClick={(_) => {
                                            dispatch({type: "SELECT_RESOURCES", payload: {
                                                resource_id: resource.resource_id,
                                                selected_resources: [item.id]
                                            }})
                                        }}
                                    >
                                        <FormCheckBox
                                            className={'justify-center w-full -translate-y-px'}
                                            onChange={() => {}}
                                            checked={(resource_selected ?? []).includes(item.id)}
                                        />
                                    </td>
                                )
                            }
                            {
                                table_schema.map((td, s_index) => {

                                    const getValue = (type: "sub_field_name" | "pre_field_name" | "field_name") => {


                                        let val: any;
                                        td[type].split(".").forEach(key => {
                                            if (val)
                                                val = val[key];
                                            else
                                                val = item[key]
                                        })

                                        const field_type = () => {
                                            switch (type) {
                                                case "field_name":
                                                    return "field_type"
                                                case "pre_field_name":
                                                    return "pre_field_type"
                                                case "sub_field_name":
                                                    return "sub_field_type"
                                            }
                                        }

                                        const field_formatting = () => {
                                            switch (type) {
                                                case "field_name":
                                                    return "field_formatting"
                                                case "pre_field_name":
                                                    return "pre_field_formatting"
                                                case "sub_field_name":
                                                    return "sub_field_formatting"
                                            }
                                        }

                                        switch (td[field_type()]) {
                                            case "date":
                                                return moment(val?.toDate()).format(td[field_formatting()].date_format);
                                            case "number":
                                                return val ?? "□";
                                            case "string":
                                                return val ?? "□";
                                            case "currency":
                                                return Intl.NumberFormat(td[field_formatting()].locale, { style: "currency", currency: td[field_formatting()].currency, minimumFractionDigits: 2 }).format(val)
                                            case "pill":
                                                let config = td[field_formatting()].pill_configuration.find(config => config.value === val);
                                                return <Pill color={config?.color ?? "bare"} pillStyle={config?.style ?? "filled"}>
                                                    {config?.label ?? ""}
                                                </Pill>;
                                            default:
                                                return "";
                                        }
                                    }

                                    return (
                                        <td className={`${td.mq_class}`} key={`td_${item.id}_${s_index}`}>
                                            <Link
                                                to={`/${resource.resource_slug}/${item.id}`}
                                                className={`${td.type === "main" ? "hover:text-primary-default font-medium" : ""} ${s_index === (table_schema.length-1) ? "justify-end" : ""} w-full h-full`}
                                                tabIndex={td.type === "main" ? undefined : -1}
                                            >
                                                <div>
                                                    {
                                                        td.pre_field_name && (
                                                            <div className={'text-xs opacity-70'}>
                                                                {td.pre_field_formatting?.prefix ?? ""}
                                                                {getValue("pre_field_name")}
                                                                {td.pre_field_formatting?.suffix ?? ""}
                                                            </div>
                                                        )
                                                    }
                                                    <div>
                                                        {td.field_formatting?.prefix ?? ""}
                                                        {getValue('field_name')}
                                                        {td.field_formatting?.suffix ?? ""}
                                                    </div>
                                                    {
                                                        td.sub_field_name && (
                                                            <div className={'text-sm opacity-70'}>
                                                                {td.sub_field_formatting?.prefix ?? ""}
                                                                {getValue("sub_field_name")}
                                                                {td.sub_field_formatting?.suffix ?? ""}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Link>
                                        </td>
                                    )

                                })
                            }
                        </tr>
                    )

                })
            }
        </>
    )

}

export default ResourceRows;