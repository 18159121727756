import Card from "../../../../components/cards/card";
import React, {useEffect, useState} from "react";
import {ProductItem} from "../../../../models/product-item";
import SlidingDialog from "../../../../components/dialogs/sliding-dialog";
import ResourceHeading from "../../components/resource-heading/resource-heading";
import FileSelector from "../../../../page-components/file-selector/file-selector";
import {FiX} from "react-icons/fi";

const ProductMedia = ({product, onChange}: {product?: ProductItem, onChange: (value: any) => void}) => {

    const [selector, setSelector] = useState(-1);
    const [image, setImage] = useState<string[]>();

    useEffect(() => {
        setImage(product?.product_images);
    }, [product])

    const imageKitLink = (imgUrl:string)  =>{
        return imgUrl ? `https://ik.imagekit.io/groomd${imgUrl}?tr=f-webp,w-380` : "";
    }

    const Image = (img: string, index:number) => {

        return (
            <div className={"group relative bg-gray-100 w-full aspect-h-1 aspect-w-1 rounded-lg overflow-hidden"}>
                {img &&
                    <img alt={"Images for " + product?.title} src={imageKitLink(img)} className={"object-cover w-full"}/>
                }
                {img &&
                    <div
                        className={"flex items-end space-x-2 transform -translate-y-3 absolute bottom-3 left-3 transition-all duration-200 opacity-0 group-hover:opacity-100"}>
                        <button
                            onClick={() => {
                                setSelector(index)
                            }}
                            className={"px-3.5 flex items-center h-8 rounded-full focus:outline-none ring-offset-transparent focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-raisinBlack text-white shadow-sm aspect-none bottom-3 left-3"}>
                            Change
                        </button>
                        <button
                            onClick={() => {
                                setImage((prevState) => {
                                    let t = prevState;
                                    t?.splice(index, 1)
                                    onChange({
                                        product_images: t
                                    });
                                    return t;
                                })
                            }}
                            className={"w-8 justify-center flex items-center h-8 rounded-full focus:outline-none ring-offset-transparent focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-raisinBlack text-white shadow-sm aspect-none"}>
                            <FiX className={"text-white"}/>
                        </button>
                    </div>
                }
                {!img &&
                <div>
                    <button
                        onClick={() => { setSelector(index) }}
                        className={"absolute px-3.5 py-1.5 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light font-medium text-sm bg-primary-default text-primary-lighter shadow-sm aspect-none bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2"}>
                        Add
                    </button>
                </div>
                }
            </div>
        )
    }

    return (
        <Card className={"overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm"}>
                Product Images
            </div>
            <div className={"grid grid-cols-3 gap-4"}>
                {
                    image?.map((img, index) => {
                        return Image(img, index);
                    })
                }
                {Image("", image?.length ?? 0)}
            </div>
            <SlidingDialog open={selector >= 0} onClose={() => { setSelector(-1) }}>
                <div className={"p-6 bg-gray-50 sticky top-0"}>
                    <ResourceHeading returnTo={() => { setSelector(-1) }} title={"Select Images"}/>
                </div>
                <div className={"p-6"}>
                    <FileSelector onSelect={(v) => {
                        setSelector(-1);
                        setImage((prevState) => {
                            let t = prevState;
                            if (t && ((t?.length ?? 0)) >= (selector+1)) {
                                t[selector] = v;
                            } else {
                                if (t)
                                    t.push(v);
                            }
                            onChange({
                                product_images: t
                            });
                            return t;
                        })
                    }} allowed={["images"]}/>
                </div>
            </SlidingDialog>

        </Card>
    )

}

export default ProductMedia;