import React from "react";
import {
    DatePickerStateProvider,
    useContextCalendars,
    useContextDaysPropGetters,
    useContextMonthsPropGetters
} from "@rehookify/datepicker";
import {ArrowLeft, ArrowRight} from "react-iconly";
import "./data-range-picker-styles.css"
import DatePresetPicker from "./date-preset-picker";

const DatePicker = () => {

    const { calendars, weekDays } = useContextCalendars();
    const { previousMonthButton, nextMonthButton } = useContextMonthsPropGetters();
    const { dayButton } = useContextDaysPropGetters()
    const { days, month, year } = calendars[0]

    return (
        <div className="">
            <div className={'flex items-center pl-1 mb-3'}>
                <span className={'font-semibold text-lg '}>{month} {year}</span>
                <div className={'flex ml-auto items-center space-x-2'}>
                    <button className={`drp-button ${previousMonthButton().disabled ? 'disabled' : ''}`} {...previousMonthButton()}>
                        <ArrowLeft size={18}/>
                    </button>
                    <button className={`drp-button ${nextMonthButton().disabled ? 'disabled' : ''}`} {...nextMonthButton()}>
                        <ArrowRight size={18}/>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-7 gap-y-2 mb-3 items-center h-8">
                {weekDays.map((d) => (
                    <p key={d} className={"w-9 text-xs text-center"}>{d[0]}</p>
                ))}
            </div>
            <div className={"grid grid-cols-7 gap-y-2"}>
                {days.map((d) => (
                    <button
                        key={d.$date.toString()}
                        disabled={d.disabled || !d.inCurrentMonth}
                        className={`text-sm w-full sm:w-10 h-9 drp-day 
                            ${d.now ? 'current-day' : ''}
                            ${d.inCurrentMonth ? 'current-month' : 'not-current-month'}
                            ${d.disabled ? 'disabled' : ''}
                            ${d.range === "" ? "not-in-range" : d.range} 
                        `}
                        {...dayButton(d)}
                    >
                        {d.day}
                    </button>
                ))}
            </div>
        </div>
    );
}

const DateRangePicker = ({value, onChange}: { value?: Date[], onChange:(d: Date[]) => void }) => {

    const D = new Date().getDate()
    const M = new Date().getMonth()
    const Y = new Date().getFullYear()

    return (
        <DatePickerStateProvider
            config={{
                selectedDates: value,
                onDatesChange: onChange,
                dates: {
                    selectSameDate: true,
                    mode: "range",
                    maxDate: new Date(Y, M, D+1),
                },
                calendar: {
                    mode: "static"
                }
            }}
        >
            <div className={'px-0 sm:px-4 pb-0 h-auto sm:h-[25rem] flex flex-col sm:flex-row items-start'}>
                <DatePresetPicker value={value ?? []} onChange={onChange}/>
                <div className={'w-full pt-4 px-4 sm:px-0'}>
                    <DatePicker />
                </div>
            </div>
        </DatePickerStateProvider>
    )

}

export default DateRangePicker;