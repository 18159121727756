import {ProductActionTypes, ProductsState, PUSH_PRODUCTS, UPDATE_PRODUCT} from "../types/product-types";
import {ProductItem} from "../../models/product-item";

const initialState: ProductsState = {
    loading: true,
    products: []
};

export function productReducer (state = initialState, action: ProductActionTypes): ProductsState {
    switch (action.type) {
        case PUSH_PRODUCTS:

            let initialValue = [...state.products];
            let newValues: ProductItem[] = [...action.payload.products];

            initialValue.forEach((o) => {
                if (newValues.findIndex((ord) => ord.id === o.id) === -1)
                    newValues.push(o);
            })

            newValues.sort((a,b) => a.title.localeCompare(b.title))

            return {
                ...state,
                loading: false,
                products: newValues
            };

        case UPDATE_PRODUCT:
            let initialValueX = [...state.products];
            let toUpdateIndex = initialValueX.findIndex((o) => o.id === action.payload.product.id);

            if (toUpdateIndex !== -1) {
                initialValueX[toUpdateIndex] = action.payload.product;
            }

            return {
                ...state,
                loading: false,
                products: initialValueX
            };
        default:
            return state
    }
}

export default productReducer;