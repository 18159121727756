import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {limit, onSnapshot, orderBy, query, where, QueryFieldFilterConstraint, QueryOrderByConstraint, collection} from "firebase/firestore";
import {Order} from "../../../../models/order";
import {firestore} from '../../../../utils/firebase.utils'
import {RootState} from "../../../../redux/index.reducers";
import {ResourceContext} from "../resource-context/resource-context";
import {PUSH_RESOURCES, ResourceActionTypes} from "../../../../redux/types/resource-types";

const OrderData = () => {

    const resource = useContext(ResourceContext);
    const {clauses: { [resource.resource_id]: clauses }, clause_changed: { [resource.resource_id]: clause_changed }} = useSelector((state: RootState) => state.resources);

    const dispatch = useDispatch<Dispatch<ResourceActionTypes>>()

    useEffect(() => {
        const where_clauses: QueryFieldFilterConstraint[] = (clauses ?? []).map(cl => where(cl.field_path, cl.op, cl.value));
        const sort_clause: QueryOrderByConstraint = orderBy(resource.default_sort_field, resource.default_sort_direction);

        const ref = query(
            collection(firestore, resource.resource_id),
            ...where_clauses,
            sort_clause,
            limit(25)
        );

        let observer = onSnapshot(ref, (snapshot) => {
            if (!snapshot.empty) {
                let t: Order[] = [];
                snapshot.docs.forEach((doc) => {
                    let data:any = doc.data();
                    data.id = doc.id;
                    t.push(data);
                })
                dispatch({type: PUSH_RESOURCES, payload: { resource_id: resource.resource_id, resources: t, action: (clause_changed ?? false) ? 'replace' : 'sync' }});
            } else {
                dispatch({type: PUSH_RESOURCES, payload: { resource_id: resource.resource_id, resources: [], action: (clause_changed ?? false) ? 'replace' : 'sync' }});
            }
        })

        return () => observer();
        // eslint-disable-next-line
    }, [dispatch, clauses])

    return (<></>)

}

export default OrderData;