import React, {useState} from "react";
import PrimaryButton from "../../../../components/buttons/button";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import SecondaryButton from "../../../../components/buttons/seondary-button";

const StringListPanel = ({options, value = [], onChange, onClose}: {options: { value: string, label: string}[], value: string[], onChange: (v: string[]) => void, onClose: () => void}) => {

    const [string_list, setStringList] = useState<string[]>(value);

    const changeStringList = () => {
        onChange(string_list);
        onClose();
    }

    const clearStringList = () => {
        onChange([]);
        onClose();
    }

    return (
        <div className={'min-w-[350px]'}>
            <div className={'max-h-[36rem] sm:max-h-none h-auto sm:h-[25rem] overflow-y-auto p-3 space-y-1'}>
                {
                    options.map((option) => {
                        return (
                            <div key={option.value} className={'rounded-xl border border-transparent hover:bg-lowlight dark:hover:bg-higherlight px-2.5 py-2 pr-3'} onClick={() => {
                                if (string_list.includes(option.value)) {
                                    setStringList(prev_list => prev_list.filter(str => str !== option.value))
                                } else {
                                    setStringList(prev_list => [...prev_list, option.value])
                                }
                            }}>
                                <FormCheckBox label={option.label} value={option.value} checked={string_list.includes(option.value)} onChange={() => {}}/>
                            </div>
                        )
                    })
                }
            </div>
            <div className={'px-4 h-[3rem] flex items-start justify-end space-x-3'}>
                <div>
                    <SecondaryButton
                        style={{ padding: "4px 12px", borderRadius: "10px" }}
                        onClick={clearStringList}
                    >
                        Clear
                    </SecondaryButton>
                </div>
                <div>
                    <PrimaryButton
                        style={{ padding: "4px 12px", borderRadius: "10px" }}
                        onClick={changeStringList}
                    >
                        Apply
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )

}

export default StringListPanel;