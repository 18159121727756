import React from "react";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import {FiCircle} from "react-icons/fi";
import {ProductItem} from "../../../../models/product-item";
import {useNavigate} from "react-router-dom";

const ProductRow = ({product, selected, onSelect}: { product: ProductItem, selected: string[], onSelect: (id:string) => void }) => {

    const navigate = useNavigate();

    return (
        <tr className={"group border-b border-gray-200 hover:bg-gray-50 cursor-pointer"} onClick={() => { navigate("/products/" + product.id); }}>
            <td className={"px-3"} onClick={(event) => { event.stopPropagation(); onSelect(product.id) }}>
                <FormCheckBox  checked={selected.includes(product.id)} onChange={() => {}}/>
            </td>
            <td className="sm:p-3 py-2 px-1">
                <div className={"flex items-center space-x-3"}>
                    <div className="h-10 w-10 rounded-md bg-gray-50 overflow-hidden">
                        <img src={product.thumbnail_src} alt={product.title} className={"object-cover"}/>
                    </div>
                    <div className={"group-hover:text-primary-default"}>
                        <span className={"font-medium"}>
                            {product.title}
                        </span>
                        <div className={"text-gray-500"}>
                            {product.categoryTitle}
                        </div>
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1" >
                <div className="flex items-center">
                    {
                        product.disabled ?
                            <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                Disabled
                            </div>
                            :
                            (product.out_of_stock ?
                                    <div className={"flex items-center px-3 py-1 bg-gray-50 border border-1 border-gray-100 text-gray-800 rounded-full font-medium"}>
                                        <FiCircle className={"mr-2 text-gray-800"} size={8} strokeWidth={5}/> Out of Stock
                                    </div>
                                    :
                                    <div className={"px-3 py-1 bg-green-100 text-green-800 rounded-full font-medium"}>
                                        Active
                                    </div>
                            )
                    }
                </div>
            </td>
        </tr>
    )

}

export default ProductRow;