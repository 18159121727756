import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {Role, SET_USER, UPDATE_USER, UserActionTypes} from "./redux/types/user-data-types";
import {RootState} from "./redux/index.reducers";
import {auth, resource_schemas, users} from "./utils/firebase.utils";
import {UserData} from "./models/user-data";
import AppRoutes from "./components/app-routes/app-routes";
import {doc, getDoc, getDocs} from 'firebase/firestore';
import {onAuthStateChanged} from 'firebase/auth';
import {ScrollRestoration} from 'react-router-dom'
import {PUSH_RESOURCE_SCHEMAS, ResourceSchemaActionTypes} from "./redux/types/resource-schema-types";
import {ResourceSchema} from "./models/resource-schema";
import {MediaQueryActionTypes, UPDATE_MEDIA_QUERIES} from "./redux/types/media-query-types";

const App = () => {

    const dispatch = useDispatch<Dispatch<UserActionTypes | ResourceSchemaActionTypes | MediaQueryActionTypes>>();
    const userData = useSelector((state: RootState) => state.userData);
    const {loading} = useSelector((state: RootState) => state.resource_schemas);

    useEffect(() => {
        dispatch({ type: UPDATE_MEDIA_QUERIES, payload: {} })
    }, [dispatch]);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                auth.currentUser?.getIdTokenResult()
                    .then(async (result) => {

                        let roles: Role[] = [];
                        if (result.claims["app_roles"]) {
                            roles = result.claims["app_roles"] as Role[];
                        }

                        dispatch({type: SET_USER, payload: {user: user, userData: new UserData({uid: user.uid, email: user.email}), role: roles}});
                    })
                    .then(async () => {
                        let document = await getDoc(doc(users, user.uid));
                        let userData;
                        if (document.exists()) {
                            let data = document.data();
                            data.uid = user.uid;
                            userData = new UserData(data);
                        } else {
                            userData = new UserData({
                                uid: user.uid, email: user.email
                            });
                        }
                        dispatch({type: UPDATE_USER, payload: {userData: userData}});
                    })
            } else {
                dispatch({type: SET_USER, payload: {user: null, userData: null, role: []}});
            }
        });


    }, [dispatch])

    useEffect(() => {
        let local_resource_schemas: ResourceSchema[] = [];
        const local_val = localStorage.getItem("resource_schemas");
        if (local_val) {
            local_resource_schemas = (JSON.parse(local_val) as any[]).map(v => new ResourceSchema(v));
            dispatch({type: PUSH_RESOURCE_SCHEMAS, payload: {resource_schemas: local_resource_schemas}});
        }

        getDocs(resource_schemas).then((snapshot) => {
            const schemas = snapshot.docs.map(s => new ResourceSchema(s.data()));
            dispatch({type: PUSH_RESOURCE_SCHEMAS, payload: {resource_schemas: schemas}});
            localStorage.setItem("resource_schemas", JSON.stringify(snapshot.docs.map(d => d.data())))
        }).catch()

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <ScrollRestoration
                getKey={(location) => {
                    return location.pathname;
                }}
            />
            {(!userData.loading && !loading) &&
                <AppRoutes/>
            }
        </>
    );
}

export default App;