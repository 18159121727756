import React, {useState} from "react";
import FormInput from "../../components/forms/form-input";
import PrimaryButton from "../../components/buttons/button";
import FormWrapper from "../../components/forms/form-wrapper";
import {auth} from "../../utils/firebase.utils";
import InlineLink from "../../components/links/inline-link";
import { sendPasswordResetEmail } from "firebase/auth";
import {FiCheck} from "react-icons/fi"
import FormErrorText from "../../components/forms/form-error-text";

const ForgotPassword = () => {

    const [success, setSuccess] = useState(false);

    // Handle Forgot Password
    const sendEmail = (values: Record<string, string | boolean>) => {
        return new Promise((resolve, reject) => {
            if (!success) {
                sendPasswordResetEmail(auth, values.email.toString())
                    .then(() => {
                        resolve("Success");
                        setSuccess(true);
                    })
                    .catch(async (err) => {
                        console.log(err.code);
                        switch (err.code) {
                            case "auth/user-not-found":
                                return reject({email: "Couldn't find your account."});
                            case "auth/invalid-email":
                                return reject({email: "Please enter a valid Email Address."});
                            default:
                                return reject("Something went wrong on our side.");
                        }
                    });
            } else {
                resolve("");
            }
        });
    };

    return (
        <div className="min-h-screen flex justify-center items-start sm:items-center">
            <div className="max-w-md w-full m-6 mt-6 sm:m-20">
                <div className={"mb-6 w-full aspect-w-7 aspect-h-2 bg-background-dark relative block sm:hidden rounded-2xl overflow-hidden"}>
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80") left top / cover` }}/>
                </div>
                <h2 className="text-4xl font-bold ">Reset your password</h2>
                <p className="text-lg text-color-light mt-2">
                    Enter your email and we'll send you instructions on how to reset your password.
                </p>
                <FormWrapper onSubmit={sendEmail} className={"space-y-4 mt-6"} onValueChange={() => { if (success) { setSuccess(false) } }}>
                    <div>
                        <FormInput
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            placeholder="Email address"
                            label="Email address"
                            hideLabel
                            required
                        />
                        <FormErrorText/>
                    </div>
                    <PrimaryButton type={"submit"} icon={success ? FiCheck : undefined} className={`${success ? "pointer-events-none" : ""}`}>
                        {success ? "Sent" : "Send Instructions"}
                    </PrimaryButton>
                    <div className="text-sm">
                        <span>
                            Go back to {" "}
                        </span>
                        <InlineLink to={"/sign-in"}>
                            Sign In
                        </InlineLink>
                    </div>
                </FormWrapper>
            </div>
            <div className={"w-full min-h-screen bg-background-dark hidden relative sm:block"}>
                <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80") left top / cover` }}/>
            </div>
        </div>
    )
}

export default ForgotPassword;