import React from "react"

interface PillProps {
    children: React.ReactNode
    color: 'green' | 'bare' | 'red' | 'yellow'
    pillStyle: 'withdot' | 'filled' | 'outlined'
}

const Pill = ({children, color, pillStyle}: PillProps) => {

    return (
        <div className={`pill ${pillStyle} ${color}`}>
            {pillStyle === "withdot" && <div className={'dot'}/>} {children}
        </div>
    )

}

export default Pill;