import {Image, Paper, Video} from "react-iconly";
import React from "react";
import {imageKitLink} from "../../../../../utils/initialize-admin";
import {StorageReference} from "firebase/storage";
import {isImage, isVideo} from "../utils/file-utils";

const FileItem = ({item}: {item: StorageReference}) => {

    console.log(item);
    return (
        <>
            <button
                onDoubleClick={() => {
                    // if (checkAllowed(item.name))
                    // onSelect("/"+item.fullPath);
                }}
                // disabled={!checkAllowed(item.name)}
                className={`cursor-default rounded-xl border border-line text-sm hover:bg-lowlight dark:hover:bg-higherlight dark:focus:hover:bg-primary-light/20 focus:bg-primary-light/20 hover:text-primary-dark dark:hover:text-primary-lighter focus:outline-none focus:border-primary-light/50`}
            >
                <div className={'px-3.5 py-3 flex items-center'}>
                    {(() => {
                        if (isImage(item.name))
                            return <Image set="bulk" size={20} className={`text-primary-default mr-3 flex-shrink-0`}/>
                        else if (isVideo(item.name))
                            return <Video set="bulk" size={20} className={` mr-3 flex-shrink-0`}/>
                        else
                            return <Paper set="bulk" size={20} className={` mr-3 flex-shrink-0`}/>
                    })()}
                    <span className={"truncate overflow-ellipsis"}>
                        {item.name}
                    </span>
                </div>
                <div className={'px-2'}>
                    <div className={"w-full aspect-w-2 aspect-h-1 mb-2"}>
                        <img alt={"Asset Preview"} className={'absolute h-full w-full object-cover rounded-lg'} src={imageKitLink(`/${item.fullPath}`, { tr: "w-180,f-webp" })}/>
                    </div>
                </div>
            </button>
        </>
    )

}

export default FileItem