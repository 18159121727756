import React from "react";

interface PrimaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: React.FunctionComponent<any>,
    loading?: boolean
}

const SecondaryButton = (props: PrimaryButtonProps) => {

    const inputProps = {...props};
    delete inputProps.icon
    delete inputProps.loading

    return (
        <button
            {...inputProps}
            disabled={props.loading || props.disabled}
            className={`group relative w-full flex justify-center py-2 px-4 border border-line text-md font-medium rounded-md text-primary-default bg-higherlight hover:bg-highlight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 ${inputProps.className}`}
        >
            <div className={`absolute left-50 inset-y-0 flex items-center transition duration-200 ${props.loading ? "opacity-100" : "opacity-0"}`}>
                <svg className="animate-spin h-5 w-5 mr-3 text-white" fill={"none"} viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </div>
            <span className={`absolute left-0 inset-y-0 flex items-center pl-3`}>
                {props.icon &&
                    React.createElement(props.icon, {className: "h-5 w-5 text-primary group-hover:opacity-90", "aria-hidden": "true"})
                }
            </span>
            <span className={`transition duration-200 ${props.loading ? "opacity-0" : "opacity-100"}`}>
                {props.children}
            </span>
        </button>
    )

}

export default SecondaryButton;