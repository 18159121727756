import {Link} from "react-router-dom";
import React, {MouseEventHandler} from "react";

const InlineLink = ({ children, to, onClick, className }: { children: React.ReactNode, onClick?: MouseEventHandler<HTMLDivElement>, to?: string, icon?: React.ReactNode, className?: string }) => {

    const defaultClass = "font-medium text-primary-default hover:text-primary-light focus-visible:outline focus-visible:outline-offset-4 focus-visible:outline-2 focus-visible:outline-primary-default rounded";

    return (
        to ?
            (
                to.startsWith("http") ?
                    <a href={to} target={"_blank"} rel={"noreferrer"} className={`${defaultClass} ${className}`}>
                        {children}
                    </a>
                    :
                    <Link to={to}  className={`${defaultClass} ${className}`}>
                        {children}
                    </Link>
            )
            :
            <span onClick={onClick} className={`${defaultClass} ${className}`}>
                {children}
            </span>
    )
}

export default InlineLink;