import ResourceHeading from "../components/resource-heading/resource-heading";
import React, {useEffect, useState} from "react";
import {ResourceAction} from "../../../models/resource-schema";
import ResourceActions from "../components/resource-actions/resource-actions";
import {list, ref, StorageReference} from "firebase/storage";
import {storage} from "../../../utils/firebase.utils";
import FileFolder from "./components/file-views/file-folder";
import FileItem from "./components/file-views/file-item";

const FILES_ACTIONS: ResourceAction[] = [
    {
        type: "primary-button",
        label: "Upload Files",
        icon: "upload",
        to: "?dialog=upload"
    }
]

const Files = () => {

    // const allowed = ["images"];
    const [path, setPath] = useState("/");
    const [loading, setLoading] = useState(true);

    const [prefixes, setPrefixes] = useState<StorageReference[]>([]);
    const [items, setItems] = useState<StorageReference[]>([]);

    useEffect(() => {
        const listRef = ref(storage, path);
        setLoading(true);
        list(listRef, { maxResults: 100 }).then((page) => {
            setPrefixes(page.prefixes);
            setItems(page.items);
            setLoading(false);
        });
    }, [path])

    // const checkAllowed = (path:string) => {
    //     let allow = false;
    //     if (allowed.includes("images")) {
    //         allow = isImage(path)
    //     }
    //     if (allowed.includes("video")) {
    //         allow = isVideo(path)
    //     }
    //
    //     return allow;
    // }

    return (
        <>
            <div className={"flex flex-col w-full bg-background-default sticky top-16 md:top-0 z-40"}>
                <div className={"h-24 border-b border-line"}>
                    <div className={'flex items-start mt-4 sm:mt-7'}>
                        <ResourceHeading title={"Files"}/>
                        <ResourceActions resource_actions={FILES_ACTIONS}/>
                    </div>
                </div>
            </div>
            <div>
                <div className={"flex py-2 mb-4 "}>
                    <p className={"font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"} onClick={() => { setPath("/") }}>
                        Files
                    </p>
                    {path.split("/").map((p, index) => {
                        if (index !== 0) {
                            return <div className={"flex"}>
                                <p className={"mx-2 font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"} onClick={() => { setPath(p) }}>
                                    /
                                </p>
                                <p className={"font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"}
                                   onClick={() => {
                                       let currentPath = "";
                                       path.split("/").forEach((xP, xIndex) => {
                                           if (xIndex!==0 && xIndex <= index) {
                                               currentPath = `${currentPath}/${xP}`
                                           }
                                       })
                                       setPath(currentPath)
                                   }}
                                >
                                    {p}
                                </p>
                            </div>
                        } else {
                            return <div/>
                        }
                    })}
                </div>
                <div className={`grid grid-cols-6 gap-4 transition duration-150 ${loading ? "opacity-0" : "opacity-100"}`}>
                    {prefixes.map((prefix) => {
                        return <FileFolder name={prefix.name}/>
                    })}
                    {items.map((item) => {

                        // let color =  checkAllowed(item.name)  ? "text-primary-light" : "text-gray-400"

                        return <FileItem item={item}/>
                    })}
                </div>
            </div>
        </>
    )

}

export default Files