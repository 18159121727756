import React from "react";
import { FiAlertCircle } from "react-icons/fi"

interface FormInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    disableRounded?: boolean,
    label?: string,
    hideLabel?: boolean,
    error?: string,
    hideShadow?: boolean,
    rootClassName?: string
}

const FormInput = (props: FormInputProps) => {

    const inputProps = {...props};
    delete inputProps.disableRounded
    delete inputProps.label
    delete inputProps.hideLabel
    delete inputProps.error
    delete inputProps.hideShadow
    delete inputProps.rootClassName

    return (
        <div className={`${props.rootClassName}`}>
            <label htmlFor={inputProps.id} className={props.hideLabel ? "sr-only" : "text-xs opacity-80"}>
                {props.label}
            </label>
            <div className={`relative -space-y-px ${props.hideShadow ? "" : "rounded-md shadow-sm"}`}>
                <input {...inputProps}
                       className={`
                           appearance-none bg-higherlight rounded-none relative block w-full px-3.5 py-2.5 border placeholder-gray-500 
                           focus:z-20 focus:outline-none 
                           ${props.error ? "border-color-error z-10 focus:ring-color-error focus:border-color-error caret-color-error" : "border-line focus:ring-primary-default focus:border-primary-default caret-primary-default"}  
                           ${props.className} 
                           ${props.disableRounded ? "" : "rounded-xl"}
                       `}
                       disabled={props.disabled}
                />
                {(!!props.error) &&
                <div className={"absolute z-20 right-3 top-1/2 transform -translate-y-1/2"}>
                    <FiAlertCircle className={"text-color-error"} size={14}/>
                </div>
                }
            </div>
            {(!!props.error && props.error !== "empty") &&
                <p style={{ textTransform:"none"}} className={"-space-y-px text-sm mt-1 text-color-error"}>
                    {props.error}
                </p>
            }
        </div>
    )

}

export default FormInput;