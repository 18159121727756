import {
    PUSH_RESOURCES,
    REMOVE_WHERE_CLAUSES,
    ResourceActionTypes,
    ResourcesState,
    SELECT_RESOURCES,
    UNSELECT_ALL_RESOURCES,
    UPDATE_RESOURCE,
    UPDATE_WHERE_CLAUSES,
    WhereClause
} from "../types/resource-types";

const initialState: ResourcesState = {
    loading: {},
    clause_changed: {},
    resources: {},
    clauses: {},
    selected_resources: {}
};

export function resourceReducer (state = initialState, action: ResourceActionTypes): ResourcesState {
    switch (action.type) {
        case PUSH_RESOURCES:
            if (action.payload.action === 'sync') {
                let initialResourceValue = [...(state.resources[action.payload.resource_id] ?? [])];
                let newResources: any[] = [...action.payload.resources];

                initialResourceValue.forEach((ir) => {
                    if (newResources.findIndex((nr) => nr.id === ir.id) === -1)
                        newResources.push(ir);
                })

                // TODO Implement Dynamic Sorting Logic
                // newOrders.sort((a,b) => b.created.getTime() - a.created.getTime())

                return {
                    ...state,
                    loading: {...state.loading, [action.payload.resource_id]: false},
                    clause_changed: {...state.clause_changed, [action.payload.resource_id]: false},
                    resources: {...state.resources, [action.payload.resource_id]: newResources}
                };
            } else {
                return {
                    ...state,
                    loading: {...state.loading, [action.payload.resource_id]: false},
                    clause_changed: {...state.clause_changed, [action.payload.resource_id]: false},
                    resources: {...state.resources, [action.payload.resource_id]: action.payload.resources}
                };
            }
        case UPDATE_RESOURCE:
            let initialResourceValue = [...state.resources[action.payload.resource_id]];
            let toUpdateIndex = state.resources[action.payload.resource_id].findIndex((o) => o.id === action.payload.resource.id);

            if (toUpdateIndex !== -1) {
                initialResourceValue[toUpdateIndex] = action.payload.resource;
            }

            return {
                ...state,
                loading: {...state.loading, [action.payload.resource_id]: false},
                resources: {...state.resources, [action.payload.resource_id]: initialResourceValue}
            };
        case UPDATE_WHERE_CLAUSES:

            let old_clauses = state.clauses[action.payload.resource_id];
            let new_clauses = action.payload.clauses;

            old_clauses.forEach(oc => {
                if (new_clauses.findIndex(nc => oc.field_path === nc.field_path) === -1) {
                    new_clauses.push(...old_clauses.filter(cl => cl.field_path === oc.field_path));
                }
            })

            return {
                ...state,
                clause_changed: {...state.clause_changed, [action.payload.resource_id]: JSON.stringify(old_clauses) !== JSON.stringify(new_clauses)},
                clauses: {...state.clauses, [action.payload.resource_id]: new_clauses}
            };
        case REMOVE_WHERE_CLAUSES:

            let current_clauses = state.clauses[action.payload.resource_id] ?? [];
            let updated_clauses: WhereClause[] = [];

            current_clauses.forEach(oc => {
                if (oc.field_path !== action.payload.clauses_field) {
                    updated_clauses.push(oc);
                }
            })

            return {
                ...state,
                clause_changed: {...state.clause_changed, [action.payload.resource_id]: JSON.stringify(current_clauses) !== JSON.stringify(updated_clauses)},
                clauses: {...state.clauses, [action.payload.resource_id]: updated_clauses}
            };
        case SELECT_RESOURCES:

            let current_selected: string[] = [...(state.selected_resources[action.payload.resource_id] ?? [])];

            if (action.payload.selected_resources.length > 1) {
                current_selected = action.payload.selected_resources;
            } else {
                const selected_id = action.payload.selected_resources[0];
                if (current_selected.includes(selected_id)) {
                    current_selected.splice(current_selected.indexOf(selected_id), 1);
                } else {
                    current_selected.push(selected_id);
                }
            }

            return {
                ...state,
                selected_resources: {...state.selected_resources, [action.payload.resource_id]: current_selected}
            };
        case UNSELECT_ALL_RESOURCES:
            return {
                ...state,
                selected_resources: {...state.selected_resources, [action.payload.resource_id]: []}
            };
        default:
            return state;
    }
}

export default resourceReducer;