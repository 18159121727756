import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import {getFirebaseConfig} from "./initialize-admin";

const firebaseApp = initializeApp(getFirebaseConfig());


// Firebase Instances
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const messaging = getMessaging(firebaseApp);


// Collections
export const resource_schemas = collection(firestore, "_admin_resources_");
export const users = collection(firestore, "users");
export const orders = collection(firestore, "orders");
export const products = collection(firestore, "products");
export const data_index = collection(firestore, "data_index");
export const sites = collection(firestore, "sites");

export const BASE_API = "https://asia-south1-groomd-mens.cloudfunctions.net"

