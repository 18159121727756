import React from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import SignIn from "../../main-pages/sign-in/sign-in";
import queryString from "query-string";
import ForgotPassword from "../../main-pages/forgot-password/forgot-password";
import DashboardWrapper from "../../main-pages/dashboard/dashboard-wrapper";
import {Role} from "../../redux/types/user-data-types";

const NoAuthRoute = ({redirect = true, children}: { children: React.ReactNode, redirect?: boolean }) => {

    const location = useLocation();
    const { isAuthenticated } = useSelector((state: RootState) => state.userData);

    const redirectUrl = queryString.parse(location?.search ?? "")?.redirect ?? null;

    return (!isAuthenticated || !redirect) ?
        (children)
        :
        <Navigate to={redirectUrl!=null ? redirectUrl.toString() : "/"} replace={true} />
};

const PrivateRoute = ({ roles = [], children }: {children: React.ReactNode, roles?: Role[]}) => {

    const location = useLocation();
    const {isAuthenticated, role} = useSelector((state: RootState) => state.userData);

    let allowed:boolean = false;
    if (roles?.length > 0) {
        roles?.forEach((r) => {
            if (role?.includes(r))
                allowed = true;
        })
    } else {
        //TODO Configure Role based policy
        // if ((role?.length ?? 0) > 0)
            allowed = true;
    }

    return (isAuthenticated) ?
        (
            allowed ?
                (children)
                :
                <Navigate to={`/not-authorised`} replace={true}/>
        )
        :
        <Navigate to={`/sign-in?redirect=${location?.pathname}${location?.search}`} replace/>
};

const AppRoutes = ()  => {

    return (
        <main>
            <Routes>

                {/*Authentication Routes - Login & Forgot Password*/}
                <Route path={'/sign-in'} element={<NoAuthRoute><SignIn/></NoAuthRoute>}/>
                <Route path={'/forgot-password'} element={<NoAuthRoute><ForgotPassword/></NoAuthRoute>}/>

                {/*All Dashboard Content*/}
                <Route path={'/*'} element={<PrivateRoute><DashboardWrapper/></PrivateRoute>} />

                {/*<OpenRoute>*/}
                {/*    <div>404</div>*/}
                {/*</OpenRoute>*/}
            </Routes>
        </main>
    )
};

export default AppRoutes;

