import React from "react";
import FormInput from "../../components/forms/form-input";
import FormCheckBox from "../../components/forms/form-checkbox";
import PrimaryButton from "../../components/buttons/button";
import FormWrapper from "../../components/forms/form-wrapper";
import FormErrorText from "../../components/forms/form-error-text";
import {auth} from "../../utils/firebase.utils";
import {browserSessionPersistence, setPersistence, indexedDBLocalPersistence} from "firebase/auth";
import InlineLink from "../../components/links/inline-link";
import { signInWithEmailAndPassword } from "firebase/auth";

const SignIn = () => {

    // Handle Sign In
    const signIn = (values: Record<string, string | boolean>) => {
        return new Promise( async (resolve, reject) => {
            const persistence = values["remember_me"] ? indexedDBLocalPersistence : browserSessionPersistence;
            await setPersistence(auth, persistence);
            signInWithEmailAndPassword(auth, values['email']?.toString(), values['password']?.toString())
                .then(() => {
                    // Automatic Redirect to HomeIndex
                })
                .catch((err) => {
                    switch (err.code) {
                        case "auth/user-not-found":
                        case "auth/email-not-found":
                            return reject({email: "empty", text: "Couldn't find your account."});
                        case "auth/wrong-password":
                            return reject({password: "empty", text: "That's an incorrect password. Try again."});
                        case "auth/invalid-email":
                            return reject({email: "empty", text: "Please enter a valid Email Address."});
                        case "auth/too-many-requests":
                            return reject("Access to this account is temporarily disabled due to many failed attempts. Please reset your password.");
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        });
    };

    return (
        <div className="min-h-screen flex justify-center bg-background-default items-start sm:items-center">
            <div className="max-w-md w-full m-6 mt-6 sm:m-20">
                <div className={"mb-6 w-full aspect-w-7 aspect-h-2 bg-background-dark relative block sm:hidden rounded-2xl overflow-hidden"}>
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80") left top / cover` }}/>
                </div>
                <h2 className="text-4xl font-bold">Sign into your<br/>account</h2>
                <FormWrapper onSubmit={signIn} className={"space-y-6 mt-6"}>
                    <div>
                        <div className="rounded-xl shadow-sm -space-y-px">
                            <FormInput
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder="Email address"
                                label="Email address"
                                className={"rounded-t-xl"}
                                hideShadow
                                hideLabel
                                disableRounded
                                required
                            />
                            <FormInput
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                label="Password"
                                hideLabel
                                hideShadow
                                placeholder="Password"
                                className={"rounded-b-xl    "}
                                disableRounded
                            />
                        </div>
                        <FormErrorText/>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FormCheckBox
                                id="remember_me"
                                name="remember_me"
                                label={"Remember me"}
                            />
                        </div>
                        <div className="text-sm">
                            <InlineLink to="/forgot-password">
                                Forgot your password?
                            </InlineLink>
                        </div>
                    </div>
                    <div>
                        <PrimaryButton type={"submit"}>
                            Sign In
                        </PrimaryButton>
                    </div>
                </FormWrapper>
            </div>
            <div className={"w-full min-h-screen bg-background-dark hidden relative sm:block"}>
                <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80") left top / cover` }}/>
            </div>
        </div>
    )
}

export default SignIn;