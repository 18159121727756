import React from "react";
import moment from "moment";
import {useContextMonthsActions} from "@rehookify/datepicker";

export const DATE_PRESETS = [
    {
        label: "Today",
        date_range: [new Date(),new Date()]
    },
    {
        label: "Yesterday",
        date_range: [moment(new Date()).subtract(1, "day").toDate(),moment(new Date()).subtract(1, "day").toDate()]
    },
    {
        label: "Last 7 days",
        date_range: [moment(new Date()).subtract(7, "days").toDate(), moment(new Date()).subtract(1, "day").toDate()]
    },
    {
        label: "Last 30 days",
        date_range: [moment(new Date()).subtract(30, "days").toDate(), moment(new Date()).subtract(1, "day").toDate()]
    },
    {
        label: "Last month",
        date_range: [moment(new Date()).subtract(1, "month").startOf('month').toDate(), moment(new Date()).subtract(1, "month").endOf('month').toDate()]
    },
    {
        label: "Last year",
        date_range: [moment(new Date()).subtract(1, "year").startOf('year').toDate(), moment(new Date()).subtract(1, "year").endOf('year').toDate()]
    }
]

export const isDateRangeActive = (a: Date[], b: Date[]) => {
    if (a.length === 2 && b.length === 2) {
        return moment(a[0]).isSame(moment(b[0]), "days") && moment(a[1]).isSame(moment(b[1]), "days")
    } else {
        return false;
    }
}

const DatePresetPicker = ({value, onChange}: {value: Date[], onChange:(d: Date[]) => void;}) => {

    const { setMonth } = useContextMonthsActions();

    return (
        <div className={'min-w-0 sm:min-w-[160px] w-full sm:w-auto h-auto sm:h-full flex-shrink-0 overflow-x-auto sm:overflow-x-hidden overflow-y-hidden sm:overflow-y-auto pb-4 sm:pb-0 pt-4 sm:pt-2 pr-4 space-x-4 space-y-0 sm:space-x-0 sm:space-y-2 mr-0 sm:mr-4 relative flex sm:block border-b border-line sm:border-0'}>
            <div className={'h-full w-px bg-gradient-to-b from-line absolute top-0 right-0 hidden sm:block'}/>
            {
                DATE_PRESETS.map((preset, index) => {

                    return (
                        <div className={'flex flex-shrink-0 p-px'} key={`date_preset_${index}`}>
                            <button
                                key={`date_preset_${preset.label}`}
                                className={`w-full text-left rounded-xl ring-1 hover:bg-primary-default/10 sm:hover:bg-lowlight sm:dark:hover:bg-higherlight focus-visible:hover:bg-higherlight px-3.5 py-2 pr-3 ${isDateRangeActive(value, preset.date_range) ? 'text-primary-default dark:text-primary-light bg-primary-light/10 dark:bg-primary-default/10 sm:bg-highlight sm:dark:bg-higherlight ring-line' : 'bg-higherlight sm:bg-transparent ring-line sm:ring-transparent'}`}
                                onClick={() => {
                                    onChange(preset.date_range);
                                    setMonth(preset.date_range[1])
                                }}
                            >
                                {preset.label}
                            </button>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default DatePresetPicker;