// import React, {useRef, useState} from "react";
// import ResourceHeading from "../components/resource-heading/resource-heading";
// import {orders} from "../../../utils/firebase.utils";
// import {doc, getDoc} from "firebase/firestore";
// import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
// import {useNavigate} from "react-router-dom";
// import CustomerDetails from "./components/customer-details";
// import {Order} from "../../../models/order";
// import moment from "moment";
// import OrderPayments from "./components/order-payments";
// import OrderItems from "./components/order-items";
// import {useDispatch} from "react-redux";
// import {Dispatch} from "redux";
// import {OrderActionTypes, UPDATE_ORDER} from "../../../redux/types/order-types";

const OrderDetails = () => {

    // const navigate = useNavigate();

    // const ref = useRef<HTMLDivElement | null>(null);
    // const { id }: {id?:string} = useParams();
    // const [loading, setLoading] = useState(true);
    // const [orderData, setOrderData] = useState<Order>();

    // const dispatch = useDispatch<Dispatch<OrderActionTypes>>();

    // useEffect(() => {
    //     getDoc(doc(orders, id)).then((doc) => {
    //         if (doc.exists()) {
    //             let data = doc.data();
    //             data.id = doc.id;
    //             setOrderData(new Order(data));
    //             dispatch({type: UPDATE_ORDER, payload: { order: new Order(data) }});
    //         }
    //         setLoading(false);
    //     });
    // }, [dispatch, id]);

    return (
        <>
            {/*<OnLoadViewer loading={loading}>*/}
            {/*    <div className="sm:pt-7 py-4 flex flex-col w-full bg-white sticky top-16 z-30" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>*/}
            {/*        <ResourceHeading*/}
            {/*            title={<div>*/}
            {/*                <p className="font-bold text-3xl text-gray-900">*/}
            {/*                    #{orderData?.orderId ?? ""}*/}
            {/*                </p>*/}
            {/*                <p className="text-sm text-gray-600">*/}
            {/*                    {moment(orderData?.created).format("DD MMMM YYYY") ?? ""} at {moment(orderData?.created).format("hh:mm A") ?? ""}*/}
            {/*                </p>*/}

            {/*            </div>}*/}
            {/*            returnTo={() => {*/}
            {/*                navigate("/orders")*/}
            {/*            }}/>*/}

            {/*    </div>*/}
            {/*    <div className={"grid grid-cols-1 sm:grid-cols-7 gap-6 py-6"}>*/}
            {/*        <div className="space-y-6 col-span-1 sm:col-span-4 w-full">*/}
            {/*            <OrderItems order={orderData}/>*/}
            {/*            <OrderPayments order={orderData}/>*/}
            {/*        </div>*/}
            {/*        <div className="space-y-6 col-span-1 sm:col-span-3 w-full">*/}
            {/*            <CustomerDetails order={orderData}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</OnLoadViewer>*/}
        </>
)
}

export default OrderDetails;