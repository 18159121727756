import React from "react";
import Loading from "./loading";

const OnLoadViewer = ({loading, children, className="", component="div"}: {loading: boolean, children?: React.ReactNode, className?:string, component?: string}) => {

    return (
        <>
            {
                loading ?
                    React.createElement(component, { className: `w-full flex justify-center py-10 ${className}`, children: <td><Loading/></td> })
                    :
                    children
            }
        </>
    )


}

export default OnLoadViewer;