import PrimaryButton from "../../../../components/buttons/button";
import Icons from "../../../../components/icons/icons";
import React from "react";
import {ResourceAction} from "../../../../models/resource-schema";


const ResourceActions = ({resource_actions}: {resource_actions: ResourceAction[]}) => {

    return (
        <div className={"ml-auto flex-shrink-0"}>
            {
                resource_actions.map((r_action, index) => {
                    switch (r_action.type) {
                        case "primary-button":
                            return (
                                <PrimaryButton key={`actions_${index}_${r_action.label?.toLowerCase().replaceAll("","_")}`} to={r_action.to}>
                                    <div className={'flex items-center'}>
                                        <Icons id={r_action?.icon ?? ""} size={16} className={'mr-2'}/>
                                        <span>{r_action.label}</span>
                                    </div>
                                </PrimaryButton>
                            )
                        default:
                            return <></>
                    }
                })
            }
        </div>
    )

}

export default ResourceActions;